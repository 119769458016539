import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { DTO } from './base.dto';
import { ResponseType } from 'axios';
import { TABLE_SORT_DIRECTION } from 'src/constants';
import alarmCenter from 'src/locales/alarmCenter';
import { IPlants } from './tenant-list.dto';


export interface IAlarm {
  paginatedResults?: IAlarmItem[];
  current?: number;
  limit?: number;
  page?: number;
  total?: number;
}
export interface IAlarmItem {
  _id?: string;
  createdOn?: string;
  updatedOn?: string;
  name?: string;
  tenant_id?: string;
  location_id?: string;
  device_id?: string;
  alarm_code?: string;
  level?: string;
  event_type?: string;
  description: IDescriptionItem[];
  severity?: string;
  notes?: string;
  status?: string;
  timestamp?: string;
  tenant?: ITenantItem;
  device?: IDeviceItem[];
  location?: ILocationsItem;
  plant?: IPlants;
  system_state?: string;
}
const typeMessageAlarm = alarmCenter.messageAlarm;
export enum AlarmType{
  SYSTEM_ALARM = 'system-alarm',
  BATTERY_ALARM = 'battery-alarm',
  FAULT = 'fault'
}
export interface IDescriptionItem {
  message: Array<keyof typeof typeMessageAlarm> ;
  alarm_type: AlarmType ;
}

export interface ITenantItem {
  _id?: string;
  createdOn?: string;
  updatedOn?: string;
  name?: string;
  information?: string;
  status?: string;
  type?: string;
  plants?: IPlantItem[];
}

export interface IPlantItem {
  _id?: string;
  createdOn?: string;
  updatedOn?: string;
  name?: string;
  tenant_id?: string;
  path?: string;
  type?: string;
  status?: string;
  locations?: ILocationsItem[];
}

export interface ILocationsItem {
  _id?: string;
  createdOn?: string;
  updatedOn?: string;
  name?: string;
  tenant_id?: string;
  path?: string;
  type?: string;
  status?: string;
  location?: ILocationItem;
  plant?: IPlantItem[];
}

export interface ILocationItem {
  type?: string;
  latitude: number;
  longitude: number;
  weather_key?: string;
}

export interface IDeviceItem {
  _id?: string;
  createdOn?: string;
  updatedOn?: string;
  email?: string;
  name?: string;
  tenant_id?: string;
  location_id?: string;
  type?: string;
  status?: string;
  device_type_id?: string;
  path?: string;
  information?: string;
}

export interface IAlarmInfo {
  _id?: string;
  createdOn?: string;
  updatedOn?: string;
  device_id?: string;
  tenant_id?: string;
  location_id?: string;
  alarm_code?: string;
  level?: string;
  event_type?: string;
  description?: IDescriptionItem[];
  severity?: string;
  notes?: string;
  phaseVac?: number;
  phaseFrequency?: number;
  '3PhaseSOC'?: number;
  phase?: string;
  status?: string;
  timestamp?: string;
  tenant?: ITenantItem;
  device?: IDeviceItem;
  location?: ILocationsItem;
  timeseries?: ITenantTimeSeries;
  system_state?: string;
}

export interface ITenantTimeSeries {
  _id?: string;
  createdOn?: string;
  updatedOn?: string;
  timestamp?: string;
  metadata?: any;
  phaseVac?: string;
  systemState?: string;
  systemAlarms?: string;
  batteryAlarms?: string;
  faultType?: number;
  phaseFrequency?: string;
  phase?: string;
  ['3PhaseSOC']?: string;
}

export interface IAlarmListRequest {
  q?: string;
  status?: string;
  level?: string;
  severity?: string;
  timespan_from?: string;
  timespan_to?: string;
  sortOption?: {
    column?: string;
    sortDirection?: TABLE_SORT_DIRECTION;
  };
  page?: number;
  pageSize?: number;
  limit?: number;
  system_state?: string;
  alarm_type?: string;
}

export interface IAccountParam {
  accountId?: string;
}

export interface ResponseAlarmDTO {
  responseCode: string;
}

export interface BodyUpdateAlarmDTO {
  status?: string;
  notes?: string;
}

export class ListAlarmDTO extends DTO {
  public param: object | undefined;
  public query: IAlarmListRequest | undefined;
  public body: undefined;
  public url: string = ENDPOINT.GET_ALARM_LIST;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(request?: IAlarmListRequest) {
    super();
    this.query = request;
  }
}

export class GetAlarmDetailDTO extends DTO {
  public param: { id: string };
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.DETAIL_ALARM;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { id: string }) {
    super();
    this.param = param;
  }
}

export class UpdateAlarmDTO extends DTO {
  public param: object | undefined;
  public query: object | undefined;
  public body: unknown | undefined;
  public url: string = ENDPOINT.UPDATE_ALARM;
  public method: HTTP_METHOD = HTTP_METHOD.PUT;
  public readonly responseType: ResponseType = 'json';
  constructor(body: BodyUpdateAlarmDTO, param: { id: string }) {
    super();
    this.body = body;
    this.param = param;
  }
}
