import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { HTTP_STATUS_CODE } from 'src/constants/api';
import { TenantResponse } from 'src/constants/tenant';
import { ResponseDTO } from 'src/dto/base.dto';
import {
  GetListTenantDTO,
  GetListTenantResponse,
  GetListTenantTreeDTO,
  IGetListTenantTreeResponse,
  IQueryListTenant
} from 'src/dto/tenant.dto';
import { IHttpService } from 'src/services/http.service';

export interface ITenantStore {
  listTenant: TenantResponse[];
  getListTenant(query: Partial<IQueryListTenant>): Promise<boolean>;
  getListTenantTree(): Promise<ResponseDTO<IGetListTenantTreeResponse[]>>;
}

export class TenantStore implements ITenantStore {
  listTenant: TenantResponse[] = [];
  constructor(private readonly http: IHttpService) {
    makeAutoObservable(this, {
      listTenant: observable,
      getListTenant: action.bound
    });
  }
  public async getListTenant(
    query: Partial<IQueryListTenant>
  ): Promise<boolean> {
    const getListTenantDTO = new GetListTenantDTO(query);
    const res: ResponseDTO<GetListTenantResponse> = await this.http.request(
      getListTenantDTO
    );
    if (res.responseCode === HTTP_STATUS_CODE.SUCCESS) {
      runInAction(() => {
        res.data &&
          (this.listTenant = [...res.data.paginatedResults]);
      });
      return true;
    }
    return false;
  }

  public async getListTenantTree(): Promise<
    ResponseDTO<IGetListTenantTreeResponse[]>
  > {
    const getListTenantTree = new GetListTenantTreeDTO();
    const res: ResponseDTO<IGetListTenantTreeResponse[]> =
      await this.http.request(getListTenantTree);
    return res;
  }
}
