import { HTTP_STATUS_RESPONSE_KEY } from 'src/constants/api';
import { ResponseDTO } from 'src/dto/base.dto';
import {
  AlgorithmDTO,
  ClearAllFlagsDTO,
  CustomIPCDTO,
  CustomIPCResponseDTO,
  DisableDTO,
  EnableDTO,
  GetURLDTO,
  IBodySetCommands,
  IBodySetPoint,
  IBodySetSOC,
  RefreshCommandDTO,
  RefreshServiceDTO,
  RefreshServiceSettingDTO,
  RemoteDTO,
  RemoteResponse,
  SetCommandsDTO,
  SetEmsPointDTO,
  SetEmsSocDTO,
  SetHubPointDTO,
  SetHubSocDTO,
  SettingsDTO,
  SoftResetDTO,
  SoftwareDTO,
  StartEmsDTO,
  StartHubDTO,
  StopEmsDTO,
  StopHubDTO,
  UpdateFirmwareDTO,
  UpdateSoftwareDTO
} from 'src/dto/command.dto';
import { IDisable } from 'src/interfaces/command';
import { ISetting } from 'src/interfaces/overview';
import { IHttpService } from 'src/services/http.service';

export interface ICommandStore {
  stopEms(body: { target: string, password: string }): Promise<ResponseDTO<string>>;
  stopHub(body: { target: string, password: string }): Promise<ResponseDTO<string>>;
  startEms(body: { target: string, password: string }): Promise<ResponseDTO<string>>;
  startHub(body: { target: string, password: string }): Promise<ResponseDTO<string>>;
  clearAllFlags(body: { target: string, password: string }): Promise<ResponseDTO<string>>;
  softReset(body: { target: string, password: string }): Promise<ResponseDTO<string>>;
  getURL(fileName: string): any;
  customIPC(body: { target: string, requests: any, tag: any, topic: any, level: any }): Promise<ResponseDTO<string>>;
  getIPCResponse(body: { target: string, requests: any, tag: any, topic: any, level: any }): Promise<ResponseDTO<string>>
  setHubPoints(body: IBodySetPoint): Promise<ResponseDTO<string>>;
  setEmsPoints(body: IBodySetPoint): Promise<ResponseDTO<string>>;
  setAlgorithm(body: FormData): Promise<boolean>;
  settings(body: FormData): Promise<boolean>;
  updateFirmware(body: SoftwareDTO): Promise<ResponseDTO<string>>;
  updateSoftware(body: SoftwareDTO): Promise<ResponseDTO<string>>;
  remote(body: {
    target: string;
    password: string | undefined;
  }): Promise<ResponseDTO<RemoteResponse>>;
  setCommands(body: IBodySetCommands): Promise<boolean>;
  setEmsSoc(body: IBodySetSOC): Promise<ResponseDTO<string>>;
  setHubSoc(body: IBodySetSOC): Promise<ResponseDTO<string>>;
  disable(body: IDisable): Promise<ResponseDTO<string>>;
  enable(body: IDisable): Promise<ResponseDTO<string>>;
  refreshCommand(param: { id: string}): Promise<ResponseDTO<ISetting[]>>
  refreshService(param: { id: string}): Promise<ResponseDTO<ISetting[]>>
  refreshServiceSetting(param: { id: string},body: {serviceName: string}): Promise<ResponseDTO<ISetting[]>>
}

export class CommandStore implements ICommandStore {
  constructor(private readonly http: IHttpService) {
    // makeAutoObservable(this,
    //   {
    //     isLoggedIn: observable,
    //     userInfo: observable,
    //     rememberLoginSession: observable,
    //     afterLogin: action.bound,
    //     logout: action.bound,
    //     updateUserInfo: action.bound
    //   }
    //   )
  }

  public async stopEms(body: { target: string, password: string }) {
    const stopDTO = new StopEmsDTO(body);
    const res: ResponseDTO<string> = await this.http.request(stopDTO);
    return res;
  }

  public async stopHub(body: { target: string, password: string }) {
    const stopDTO = new StopHubDTO(body);
    const res: ResponseDTO<string> = await this.http.request(stopDTO);
    return res;
  }

  public async startEms(body: { target: string, password: string }) {
    const startDTO = new StartEmsDTO(body);
    const res: ResponseDTO<string> = await this.http.request(startDTO);
    return res;
  }

  public async startHub(body: { target: string, password: string }) {
    const startDTO = new StartHubDTO(body);
    const res: ResponseDTO<string> = await this.http.request(startDTO);
    return res;
  }

  public async clearAllFlags(body: { target: string, password: string }) {
    const clearAllFlagsDTO = new ClearAllFlagsDTO(body);
    const res: ResponseDTO<string> = await this.http.request(clearAllFlagsDTO);
    return res;
  }

  public async softReset(body: { target: string, password: string }) {
    const softResetDTO = new SoftResetDTO(body);
    const res: ResponseDTO<string> = await this.http.request(softResetDTO);
    return res;
  }

  public async getURL(fileName: string ) {
    const geturlDTO = new GetURLDTO(fileName);
    const res: ResponseDTO<string> = await this.http.request(geturlDTO);
    return res;
  }

  public async customIPC(body: { target: string, requests: any, tag: any, topic: any, level: any }) {
    const customIPCDTO = new CustomIPCDTO(body);
    const res: ResponseDTO<string> = await this.http.request(customIPCDTO);
    return res;
  }

  public async getIPCResponse(body: { target: string, requests: any, tag: any, topic: any, level: any }) {
    const customIPCResponseDTO = new CustomIPCResponseDTO(body);
    const res: ResponseDTO<string> = await this.http.request(customIPCResponseDTO);
    return res;
  }
  


  public async setHubPoints(body: IBodySetPoint) {
    const setPointDTO = new SetHubPointDTO(body);
    const res: ResponseDTO<string> = await this.http.request(setPointDTO);
    return res;
  }

  public async setAlgorithm(body: FormData): Promise<boolean> {
    const algorithmDTO = new AlgorithmDTO(body);
    const res = await this.http.request(algorithmDTO);
    return res.responseCode === HTTP_STATUS_RESPONSE_KEY.SUCCESS;
  }

  public async settings(body: FormData): Promise<boolean> {
    const settingsDto = new SettingsDTO(body);
    const res = await this.http.request(settingsDto);
    return res.responseCode === HTTP_STATUS_RESPONSE_KEY.SUCCESS;
  }
  public async updateFirmware(body: SoftwareDTO): Promise<ResponseDTO<string>> {
    const updateFirmwareDto = new UpdateFirmwareDTO(body);
    return await this.http.request(updateFirmwareDto);
  }

  public async updateSoftware(body: SoftwareDTO): Promise<ResponseDTO<string>> {
    const updateSoftwareDto = new UpdateSoftwareDTO(body);
    return await this.http.request(updateSoftwareDto);
  }

  public async remote(body: {
    target: string;
  }): Promise<ResponseDTO<RemoteResponse>> {
    const remoteDTO = new RemoteDTO(body);
    const res: ResponseDTO<RemoteResponse> = await this.http.request(remoteDTO);

    return res;
  }
  public async setCommands(body: IBodySetCommands): Promise<boolean> {
    const setCommandsDto = new SetCommandsDTO(body);
    const res = await this.http.request(setCommandsDto);
    return res.responseCode === HTTP_STATUS_RESPONSE_KEY.SUCCESS;
  }

  public async setEmsSoc(body: IBodySetSOC) {
    const setSocDto = new SetEmsSocDTO(body);
    const res: ResponseDTO<string> = await this.http.request(setSocDto);
    return res;
  }

  public async setHubSoc(body: IBodySetSOC) {
    const setSocDto = new SetHubSocDTO(body);
    const res: ResponseDTO<string> = await this.http.request(setSocDto);
    return res;
  }

  public async disable(body: IDisable) {
    const disableDto = new DisableDTO(body);
    const res: ResponseDTO<string> = await this.http.request(disableDto);
    return res;
  }
  public async enable(body: IDisable) {
    const enableDto = new EnableDTO(body);
    const res: ResponseDTO<string> = await this.http.request(enableDto);
    return res;
  }

  public async setEmsPoints(body: IBodySetPoint) {
    const setPointDTO = new SetEmsPointDTO(body);
    const res: ResponseDTO<string> = await this.http.request(setPointDTO);
    return res;
  }

  public async refreshCommand(param: {id: string}) {
    const refreshCommanDto = new RefreshCommandDTO(param);
    const res: ResponseDTO<ISetting[]> = await this.http.request(refreshCommanDto);
    return res;
  }

  public async refreshService(param: {id: string}) {
    const refreshServiceDto = new RefreshServiceDTO(param);
    const res: ResponseDTO<ISetting[]> = await this.http.request(refreshServiceDto);
    return res;
  }
  public async refreshServiceSetting(param: { id: string}, body: {serviceName: string}): Promise<ResponseDTO<ISetting[]>>{
    const refreshServiceSettingDto = new RefreshServiceSettingDTO(param,body);
    const res: ResponseDTO<ISetting[]> = await this.http.request(refreshServiceSettingDto);
    return res;
  }
}
