import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { DTO } from './base.dto';
import { ResponseType } from 'axios';
import { TABLE_SORT_DIRECTION } from 'src/constants';
import { STATUS } from 'src/constants/status';
import { IBodyGetUserAssignByEmail } from './account-management-list.dto';
import { IWeather } from 'src/interfaces/weather';
import { IAlarms, ICellDriver, ISetting } from 'src/interfaces/overview';

export interface IPlant {
  paginatedResults?: IPlantItem[];
  current?: number;
  limit?: number;
  page?: number;
  total?: number;
}

export interface IDeviceType {
  name?: string;
  sort?: number;
  _id?: string;
}
export interface IPlantItem {
  _id?: string;
  name?: string;
  tenant_id?: string;
  path?: string;
  type?: string;
  createdOn?: string;
  updatedOn?: string;
  status?: string;
  tenant?: ITenantItem;
  owners?: IOwnersItem[];
  locations?: ILocationsItem[];
  weather_info?: IWeather[];
  device_types?: IDeviceType[];
  devices?: IAssociatedAssetsItem[];
  address?: string;
  latitude?: number;
  longitude?: number;
  cell_drivers?: ICellDriver[];
  groupedAssets?: IGroupedAssetsItem[];
  system_alarms?: IAlarms[];
  battery_alarms?: IAlarms[];
  associated_assets?: IAssociatedAssetsItem[];
  setting: ISetting;
}
export interface IGroupedAssetsItem {
  device_type_id?: string;
  device_type_name?: string;
  information?: number;
}
export interface IEmsItem {
  _id: string;
  name: string;
  information: string;
  tenant_id: string;
  location_id: string;
  type: string;
  status: STATUS;
  path: string;
  associated_assets: IAssociatedAssetsItem[];
}
export interface IAssociatedAssetsItem {
  _id?: string;
  createdOn?: string;
  updatedOn?: string;
  location_id?: string;
  tenant_id?: string;
  name?: string;
  type?: string;
  status?: string;
  device_type_id?: string;
  path?: string;
  information?: string;
  capacity?: number;
  is_deleted?: boolean;
  device_typename?: string;
  device_type?: {
    _id?: string;
    name?: string;
  };
}

export interface ITenantItem {
  _id?: string;
  name?: string;
  information?: string;
  createdOn?: string;
  updatedOn?: string;
  operating_status?: string;
  asset_status?: string;
  is_deleted?: boolean;
  assetStatus?: string;
  operatingStatus?: string;
  owners?: IOwnersDetailItem[];
}

export interface IOwnersItem {
  _id?: string;
  user_id?: string;
  type?: string;
  target?: string;
  target_model?: string;
  user?: IUserItem;
  createdOn?: string;
  updatedOn?: string;
}

export interface IUserItem {
  _id?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  phone_code?: string;
  phone_number?: string;
  avatar?: string;
  isActive?: boolean;
  isFirstLogin?: boolean;
  role_id?: string;
  createdOn?: string;
  updatedOn?: string;
  is_active?: boolean;
  is_first_login?: boolean;
  full_name?: string;
  temperature_unit: string;
}

export interface ILocationsItem {
  _id?: string;
  tenant_id?: string;
  path?: string;
  location?: ILocationItem;
  address?: string;
  type?: string;
  owners?: IOwnersItem[];
  status?: string;
  ems?: IEmsItem;
  name?: string;
  is_deleted?: boolean;
  createdOn?: string;
  updateOn?: string;
  alarm?: Array<{ type: string; count: number }>;
}

export interface ILocationItem {
  type?: string;
  latitude?: number;
  longitude?: number;
  weather_key?: string;
}

export interface IPlantListRequest {
  q?: string;
  status?: string;
  sortOption?: {
    column?: string;
    sortDirection?: TABLE_SORT_DIRECTION;
  };
  page?: number;
  pageSize?: number;
  limit?: number;
}

export interface IAccountParam {
  accountId?: string;
}

export interface ResponsePlantDTO {
  responseCode: string;
}

export interface IPlantInfo {
  _id: string;
  name: string;
  type: string;
  asset_status: string;
  tenant: ITenantItem;
  owners: IOwnersDetailItem[];
  locations: ILocationsDetailItem[];
}

export interface IPlantDetailInfo {
  _id: string;
  name: string;
  information: string;
  type: string;
  status: STATUS;
  tenant: ITenantItem;
  owners: [
    {
      _id: string;
      first_name: string;
      last_name: string;
      email: string;
      avatar: string;
    }
  ];
  locations: [
    {
      _id: string;
      tenant_id: string;
      path: string;
      location: {
        type: string;
        latitude: string;
        longitude: string;
      };
      name: string;
      address: string;
      type: string;
      status: STATUS;
      owners: [
        {
          _id: string;
          first_name: string;
          last_name: string;
          email: string;
          avatar: string;
        }
      ];
      ems?: {
        _id: string;
        name: string;
        information: string;
        tenant_id: string;
        location_id: string;
        type: string;
        status: STATUS;
        path: string;
        associated_assets: [
          {
            _id: string;
            name: string;
            information: string;
            tenant_id: string;
            location_id: string;
            type: string;
            status: string;
            path: string;
            device_type: {
              _id: string;
              name: string;
            };
          }
        ];
      };
    }
  ];
}
export interface IOwnersDetailItem {
  _id?: string;
  first_name: string;
  last_name: string;
  email?: string;
  avatar?: string;
  isActive?: boolean;
  isFirstLogin?: boolean;
  activation_code?: string;
}

export interface ILocationsDetailItem {
  _id?: string;
  createdOn?: string;
  updatedOn?: string;
  name?: string;
  tenant_id?: string;
  address?: string;
  location?: ILocationDetailItem;
  path?: string;
  type?: string;
  owners?: IOwnersDetailItem[];
}

export interface ILocationDetailItem {
  latitude?: number;
  longitude?: number;
  type?: string;
  weather_key?: string;
}



export interface PlantsCreateItem {
  name?: string;
  address?: string;
  location?: ILocationDetailItem;
  emsId?: string;
  type?: string;
  owners?: string[];
}

export class ListPlantDTO extends DTO {
  public param: object | undefined;
  public query: IPlantListRequest | undefined;
  public body: undefined;
  public url: string = ENDPOINT.GET_PLANT_LIST;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(request?: IPlantListRequest) {
    super();
    this.query = request;
  }
}

export class ListPlantAddTenantDTO extends DTO {
  public param: object | undefined;
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.GET_PLANT_ADD_TENANT;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor() {
    super();
  }
}

export class DeletePlantDTO extends DTO {
  public param: { plantId: string };
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.DELETE_PLANT;
  public method: HTTP_METHOD = HTTP_METHOD.DELETE;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { plantId: string }) {
    super();
    this.param = param;
  }
}

export class ServicePlantDTO extends DTO {
  public param: { id: string };
  public query: undefined;
  public body: {component: string};
  public url: string = ENDPOINT.SERVICE_PLANT;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { id: string }, body: {component: string}) {
    super();
    this.param = param;
    this.body = body;
  }
}

export class GetPlantDetailDTO extends DTO {
  public param: { id: string };
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.DETAIL_PLANT;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { id: string }) {
    super();
    this.param = param;
  }
}


//----------------DTO Create Plant----------------------//

export interface ILocationCreatePlantDTO {
  name: string;
  address: string;
  longitude: number;
  latitude: number;
}

export interface AssociatedAssetsDTO {
  company: string;
  device_id: string;
  capacity: number;
}

export interface BodyCreatePlantDTO {
  name: string;
  location: ILocationCreatePlantDTO;
  associated_assets: AssociatedAssetsDTO[];
  owner_id?: string;
  viewers: string[];
}

export class CreatePlantDTO extends DTO {
  public param: object | undefined;
  public query: object | undefined;
  public body: unknown | undefined;
  public url: string = ENDPOINT.CREATE_PLANT;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: BodyCreatePlantDTO) {
    super();
    this.body = body;
  }
}

//-------Update Plant--------------//
export interface BodyUpdatePlantDTO extends BodyCreatePlantDTO {
  associated_assets: Array<AssociatedAssetsDTO>;
  service_arr: any;
}

export class UpdatePlanttDTO extends DTO {
  public param: object | undefined;
  public query: object | undefined;
  public body: unknown | undefined;
  public url: string = ENDPOINT.UPDATE_PLANT;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: BodyUpdatePlantDTO, param: { id: string }) {
    super();
    this.body = body;
    this.param = param;
  }
}


export class CheckExistingPlantNameDTO extends DTO {
  public param: undefined;
  public query: undefined;
  public body: { name: string };
  public url: string = ENDPOINT.CHECK_EXISTING_PLANT_NAME;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: { name: string }) {
    super();
    this.body = body;
  }
}


export class DownloadCertificateDTO extends DTO {
  public param: {id: string};
  public query: object | undefined;
  public body: undefined;
  public url: string = ENDPOINT.DOWNLOAD_CERTIFICATE;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType?: ResponseType = 'json';
  constructor(param: {id: string}) {
    super();
    this.param = param;
  }
}

 export interface IUserOwnerResponseGetByEmail {
  msg: string;
  user: {id: string; email: string; avatar?: string; first_name: string; last_name: string;}
}

export class GetUserAssignOwnerByEmailDTO extends DTO {
  public param: object | undefined;
  public query: object | undefined;
  public body: unknown | undefined;
  public url: string = ENDPOINT.GET_USER_ASSIGN_OWNER_BY_EMAIL;
  public method: HTTP_METHOD = HTTP_METHOD.POST
  ;
  public readonly responseType: ResponseType = 'json';
  constructor(body: IBodyGetUserAssignByEmail) {
    super();
    this.body = body;
  }
}

export class GetUserAssignViewerByEmailDTO extends DTO {
  public param: object | undefined;
  public query: object | undefined;
  public body: unknown | undefined;
  public url: string = ENDPOINT.GET_USER_ASSIGN_VIEWER_BY_EMAIL;
  public method: HTTP_METHOD = HTTP_METHOD.POST
  ;
  public readonly responseType: ResponseType = 'json';
  constructor(body: IBodyGetUserAssignByEmail) {
    super();
    this.body = body;
  }
}


