import { Command } from 'src/pages/system-management/command-logs/command-logs'

const commandLogs = {

    label: {
      lastUpdatedDate: 'Last Updated Date',
      queuedAt: 'Queued At',
      command: 'Command',
    },

    placeholder: {
      search: 'Search by Plant, Location, Cell Driver , Email'
    },
    command: {
      start : 'Start', 
      stop : 'Stop',
      clearAllFlags: 'Clear All Flags',
      softReset: 'Soft Reset',
      algorithm : 'ALGORITHM',
      setPoint : 'Set Points',
      remote : 'Remote',
      setOfCommands : 'Set Of Commands',
      settings: 'Settings',
      socLimit: 'Soc Limits',
      updateFirmware: 'Update Firmware',
      setSoc: 'Set Soc',
      updateSoftware: 'Update Software',
      settingDictionary: 'Setting Dictionary',
      enable: 'Enable',
      disable: 'Disable',
      refreshCommandData: 'Refresh Command Data',
      refreshPlantService: 'Refresh Plant Services',
      refreshPlantServiceSetting: 'Refresh Plant Service Setting'
    },
    commandKeyToText: {
      [Command.DISABLE]: 'Disable',
      [Command.ENABLE]: 'Enable',
      [Command.CLEAR_ALL_FLAGS]: 'Clear All Flags',
      [Command.SOFT_RESET]: 'Soft Reset',
      [Command.REMOTE]: 'Remote',
      [Command.SETTING_DICTIONARY]: 'Setting Dictionary',
      [Command.SET_POINTS]: 'Set Points',
      [Command.SOC_LIMITS]: 'Soc Limits',
      [Command.START]: 'Start',
      [Command.STOP]: 'Stop',
      [Command.CUSTOM_IPC]: 'Custom IPC',
      [Command.UPDATE_FIRMWARE]: 'Update Firmware',
      [Command.UPDATE_SOFTWARE]: 'Update Software',
      [Command.REFRESH_COMMAND_DATA]: 'Refresh Command Data',
      [Command.REFRESH_PLANT_SERVICES]: 'Refresh Plant Services',
      [Command.REFRESH_PLANT_SERVICE_SETTING]: 'Refresh Plant Service Setting'
    }
}

export default commandLogs;