import { makeAutoObservable, observable, runInAction } from 'mobx';
import { PAGINATION_CONFIGURATION, TABLE_SORT_DIRECTION } from 'src/constants';
import { HTTP_STATUS_RESPONSE_KEY } from 'src/constants/api';
import { PaginationResponseDTO } from 'src/dto/base.dto';
import {
  CommandLogsManagementDTO,
  ICommandLogsListRequest,
  ICommandLogsManagement,
  ICommandLogsManagementItem
} from 'src/dto/command-logs';
import { IHttpService } from 'src/services/http.service';

const DEFAULT_SORT_COLUMN = 'updateTime';

export interface ICommandLogsListStore {
  listCommandLog: ICommandLogsManagementItem[];
  totalPages: number;
  totalRecords: number;
  pageSize: number;
  pageNumber: number;
  sortBy: string;
  sortDirection: TABLE_SORT_DIRECTION;
  fetchList(request?: ICommandLogsListRequest): Promise<void>;
}

export class CommandLogsManagementListStore implements ICommandLogsListStore {
  listCommandLog: ICommandLogsManagementItem[] = [];
  totalPages = 0;
  totalRecords = 0;
  pageSize = PAGINATION_CONFIGURATION.DEFAULT_PAGE_SIZE;
  pageNumber = PAGINATION_CONFIGURATION.DEFAULT_PAGE;
  sortBy = DEFAULT_SORT_COLUMN;
  sortDirection = TABLE_SORT_DIRECTION.DESC;
  constructor(private http: IHttpService) {
    makeAutoObservable(this, {
      listCommandLog: observable.ref
    });
  }

  public async fetchList(request: ICommandLogsListRequest): Promise<void> {
    const requestDTO = new CommandLogsManagementDTO(request);
    const listCommandLogDto: PaginationResponseDTO<ICommandLogsManagement> =
      await this.http.request(requestDTO);

    if (listCommandLogDto.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      runInAction(() => {
        this.listCommandLog =
          listCommandLogDto.data &&
          (listCommandLogDto.data.paginatedResults as any);
        this.totalPages =
          (listCommandLogDto.data && listCommandLogDto.data.total) || 0;
        this.totalRecords = listCommandLogDto.totalRecords || 0;
        this.pageSize =
          listCommandLogDto.pageSize ||
          PAGINATION_CONFIGURATION.DEFAULT_PAGE_SIZE;
        this.pageNumber =
          listCommandLogDto.pageNumber ||
          PAGINATION_CONFIGURATION.DEFAULT_PAGE;
      });
    }
  }
}
