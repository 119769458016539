import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { PAGINATION_CONFIGURATION, TABLE_SORT_DIRECTION } from 'src/constants';
import { HTTP_STATUS_RESPONSE_KEY } from 'src/constants/api';
import { GetRoleDTO } from 'src/dto/account-management-list.dto';
import { PaginationResponse, ResponseDTO } from 'src/dto/base.dto';
import {
  BodyCreateTenantDTO,
  BodyUpdateTenantDTO,
  CreateTenantDTO,
  DeleteTenantDTO,
  GetTenantDetailDTO,
  IPlants,
  ITenantInfo,
  ITenantItem,
  ITenantListRequest,
  ListPlantTenantDTO,
  ListTenantDTO,
  ListTenantNoPermissionDTO,
  RemovePlantTenantDTO,
  ResponseTenantDTO,
  UpdateTenantDTO,
  ValidNameTenantDTO
} from 'src/dto/tenant-list.dto';
import { IUserRole } from 'src/interfaces/user';
import { IHttpService } from 'src/services/http.service';

const DEFAULT_SORT_COLUMN = 'updateTime';

export interface IQuery {
  tenant_id?: string;
}

export interface ITenantListStore {
  listTenant: ITenantItem[];
  listTenantAccount: ITenantItem[];
  currentTenantId?: string;
  listRole: IUserRole[];
  totalPages: number;
  totalRecords: number;
  pageSize: number;
  pageSizeAccount: number;
  pageNumber: number;
  sortBy: string;
  pageNumberAccount: number;
  getRole(): Promise<boolean>;
  sortDirection: TABLE_SORT_DIRECTION;
  fetchList(request?: ITenantListRequest): Promise<void>;
  deleteTenant(param: {
    tenantId: string;
  }): Promise<ResponseDTO<ResponseTenantDTO>>;
  createTenant(
    body: BodyCreateTenantDTO
  ): Promise<ResponseDTO<ResponseTenantDTO>>;
  updateTenant(
    body: BodyUpdateTenantDTO,
    param: { id: string }
  ): Promise<ResponseDTO<ResponseTenantDTO>>;
  configSortOption(sortBy: string, sortDirection: TABLE_SORT_DIRECTION): void;
  getDetailTenant(param: { id: string }): Promise<ResponseDTO<ITenantInfo>>;
  validNameTenant(param: { name: string }): Promise<ResponseDTO<any>>;
  fetchListNoPermission(request?: ITenantListRequest): Promise<void>;
  fetchLoadMoreNoPermission(request?: ITenantListRequest): Promise<void>;
  fetchListPlantTenant(query: IQuery): Promise<ResponseDTO<IPlants[]>>;
  removePlantTenant( param: { id: string }, body: { plant_id: string }): Promise<ResponseDTO<string>>;
  setCurrentId(id: string): void;
  dispose(): void;
  destroyStoreWhenLogout(): void;
}

export class TenantListStore implements ITenantListStore {
  listTenant: ITenantItem[] = [];
  listTenantAccount: ITenantItem[] = [];
  listRole: IUserRole[] = [];
  totalPages = 0;
  totalRecords = 0;
  pageSize = PAGINATION_CONFIGURATION.DEFAULT_PAGE_SIZE;
  pageSizeAccount = PAGINATION_CONFIGURATION.DEFAULT_PAGE;
  pageNumberAccount = PAGINATION_CONFIGURATION.DEFAULT_PAGE;
  pageNumber = PAGINATION_CONFIGURATION.DEFAULT_PAGE;
  sortBy = DEFAULT_SORT_COLUMN;
  sortDirection = TABLE_SORT_DIRECTION.DESC;
  currentTenantId?: string;
  constructor(private readonly http: IHttpService) {
    makeAutoObservable(this, {
      listTenant: observable.ref,
      listRole: observable.ref,
      listTenantAccount: observable.ref,
      currentTenantId: observable,
      configSortOption: action.bound
    });
  }

  public configSortOption(sortBy: string, sortDirection: TABLE_SORT_DIRECTION) {
    this.sortBy = sortBy;
    this.sortDirection = sortDirection;
  }

  public async fetchList(request: ITenantListRequest): Promise<void> {
    const requestDTO = new ListTenantDTO(request);

    const listTenantApplication: ResponseDTO<PaginationResponse<ITenantItem>> =
      await this.http.request<ListTenantDTO, PaginationResponse<ITenantItem>>(requestDTO);

    if (
      listTenantApplication.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS
    ) {
      runInAction(() => {
        if(listTenantApplication.data){
          this.listTenant = listTenantApplication.data.paginatedResults || [];
          this.totalPages = listTenantApplication.data.total || 0;
          this.pageSize = listTenantApplication.data.limit || PAGINATION_CONFIGURATION.DEFAULT_PAGE_SIZE;
          this.pageNumber = listTenantApplication.data.page || PAGINATION_CONFIGURATION.DEFAULT_PAGE;
          this.totalRecords = listTenantApplication.data.total || 0
        }
    })
  }
  }

  public async fetchListNoPermission(
    request: ITenantListRequest
  ): Promise<void> {
    const requestDTO = new ListTenantNoPermissionDTO(request);
    const listTenantApplication: ResponseDTO<PaginationResponse<ITenantItem>> =
      await this.http.request(requestDTO);

    if (
      listTenantApplication.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS
    ) {
      runInAction(() => {
        this.dispose()
        this.listTenantAccount = listTenantApplication.data &&
        (listTenantApplication.data.paginatedResults as any);
        this.totalPages =
          (listTenantApplication.data && listTenantApplication.data.total) || 0;
        this.pageSizeAccount =
          listTenantApplication.data?.limit ||
          PAGINATION_CONFIGURATION.DEFAULT_PAGE_SIZE;
        this.pageNumberAccount =
          listTenantApplication.data?.page ||
          PAGINATION_CONFIGURATION.DEFAULT_PAGE;
      });
    }
  }

  public async fetchLoadMoreNoPermission(
    request: ITenantListRequest
  ): Promise<void> {
    const requestDTO = new ListTenantNoPermissionDTO(request);
    const listTenantApplication: ResponseDTO<PaginationResponse<ITenantItem>> =
      await this.http.request(requestDTO);

    if (
      listTenantApplication.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS
    ) {
      runInAction(() => {
        this.listTenantAccount = [...this.listTenantAccount,...(listTenantApplication.data?.paginatedResults || [])];
        this.totalPages =
          (listTenantApplication.data && listTenantApplication.data.total) || 0;
        this.pageSizeAccount =
          listTenantApplication.data?.limit ||
          PAGINATION_CONFIGURATION.DEFAULT_PAGE_SIZE;
        this.pageNumberAccount =
          listTenantApplication.data?.page ||
          PAGINATION_CONFIGURATION.DEFAULT_PAGE;
      });
    }
  }

  public async deleteTenant(param: { tenantId: string }) {
    const deleteTenantDTO = new DeleteTenantDTO(param);
    const res: ResponseDTO<ResponseTenantDTO> = await this.http.request(
      deleteTenantDTO
    );
    return res;
  }
  public async createTenant(body: BodyCreateTenantDTO) {
    const createTenantDto = new CreateTenantDTO(body);
    const res: ResponseDTO<ResponseTenantDTO> = await this.http.request(
      createTenantDto
    );
    return res;
  }

  public async updateTenant(body: BodyUpdateTenantDTO, param: { id: string }) {
    const updateTenantDTO = new UpdateTenantDTO(body, param);
    const res: ResponseDTO<ResponseTenantDTO> = await this.http.request(
      updateTenantDTO
    );
    return res;
  }

  public async getRole(): Promise<boolean> {
    const getRole = new GetRoleDTO();
    const res: ResponseDTO<IUserRole[]> = await this.http.request(getRole);
    if (res.responseCode === HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      runInAction(() => {
        res.data && (this.listRole = res.data);
      });
      return true;
    }
    return false;
  }

  public async getDetailTenant(query: { id: string }) {
    const getTenantDetailDTO = new GetTenantDetailDTO(query);
    const res: ResponseDTO<ITenantInfo> = await this.http.request(
      getTenantDetailDTO
    );
    return res;
  }

  public async validNameTenant(body: { name: string }) {
    const validNameTenantDto = new ValidNameTenantDTO(body);
    const res: ResponseDTO<ResponseTenantDTO> = await this.http.request(
      validNameTenantDto
    );
    return res;
  }

  public async removePlantTenant(
    param: { id: string },
    body: { plant_id: string }
  ) {
    const requestDTO = new RemovePlantTenantDTO(param, body);
    const res: ResponseDTO<string> = await this.http.request(requestDTO);
    return res;
  }

  public async fetchListPlantTenant(query: {
    tenant_id: string;
  }): Promise<ResponseDTO<IPlants[]>> {
    const requestDTO = new ListPlantTenantDTO(query);
    return await this.http.request(requestDTO);
  }

  public setCurrentId(id: string): void{
    runInAction(()=>{
      this.currentTenantId = id;
    })
  }

  public dispose(): void {
    // this.listTenant = [];
    this.listTenantAccount = [];
    this.totalPages  = 0;
    this.pageSize = PAGINATION_CONFIGURATION.DEFAULT_PAGE_SIZE;
    this.pageNumber = PAGINATION_CONFIGURATION.DEFAULT_PAGE;
  }

  public destroyStoreWhenLogout(): void{
    this.listTenant = [];
    this.listTenantAccount = [];
    this.totalPages  = 0;
    this.pageSize = PAGINATION_CONFIGURATION.DEFAULT_PAGE_SIZE;
    this.pageNumberAccount = PAGINATION_CONFIGURATION.DEFAULT_PAGE;
    this.pageSizeAccount= PAGINATION_CONFIGURATION.DEFAULT_PAGE_SIZE;
    this.pageNumber = PAGINATION_CONFIGURATION.DEFAULT_PAGE;
    this.currentTenantId === undefined;
  }
}
