const dashboard = {
  title: {
    tenantOverview: 'Tenant Overview',
    plantOverview: 'Plant Overview',
    emsOverview: 'EMS Overview',
  },

  label: {
    shortBio: 'Short Bio',
    plantID: 'Plant ID:',
    emsID: 'EMS ID',
    cellDriverID: 'Cell Driver ID:',
    energyStatisticsToday: 'Energy Statistics Today',
    emsStatus: 'EMS Status',
    electricityConsumptionStatistics:
      '3-phase Electricity Consumption Statistics',
    alarmInfo: 'Alarm Information',
    alarmDetails: 'Alarm Details',
    batteryAlarms: 'Battery Alarms',
    systemAlarms: 'System Alarms',
    plantList: 'Plant List',
    emsList: 'EMS List',
    cellDriverList: 'Cell Driver List',
    associatedAsset: 'Associated Asset',
    cellWarnings: 'Cell Warnings',
    cellAlarms: 'Cell Alarms',
    temperature:'Temperature',
    alarm_info: 'Alarm Information',
    warning_info: 'Warning Information'
  },

  chart: {
    duration: {
      last_hours: 'Last 24 Hours',
      hour: 'Hour',
      day: 'Day',
      week: 'Week',
      month: 'Month',
      year: 'Year',
      total: 'Total',
      date_range: 'Date Range'
    }
  },

  button: {
    showMore: 'Show more',
    showLess: 'Show less',
  },

  tenant: {
    label: {
      AlarmID: 'Alarm ID'
    },
    alarms: 'Alarms',
    confirmModal: {
      stopSystem: 'Confirm Stop System',
      startSystem: 'Confirm Start System',
      stopSystemDesc: 'Are you sure you want to stop the EMS?',
      startSystemDesc: 'Are you sure you want to start the EMS?',
      emsAlgorithms: 'EMS Algorithms',
      setPoints: 'Set Points',
      setting: 'Settings',
      updateFirmware: 'UPDATE FIRMWARE',
      setOfEmsAlgorithms: 'Set Of EMS Algorithms',
      remote: 'Remote'
    },
    total3PhaseACkW: 'Total 3-Phase AC kW',
    totalkWhInjected: 'Total kWh Injected',
    totalkWhAbsorbed: 'Total kWh Absorbed',
    '3PhasekWhAvailable': '3-Phase kWh Available',
    '3PhaseSOC': '3-Phase SOC',
    '3PhaseSOH': '3-Phase SOH',
    ambientTemperature: 'Ambient Temperature',
    localLoadskW: 'Local Loads kW',
    widget: {
      alarm: 'Alarm',
      map: 'Map'
    }
  },
  plant: {
    widget: {
      alarm: 'Alarm',
      map: 'Map',
    }
  },
  ems: {
    confirmModal: {
      stopSystem: 'Confirm Stop System',
      startSystem: 'Confirm Start System',
      clearAllFlags: 'Clear All Flags in System',
      softReset: 'Soft Reset the System',
      disableSystem: 'Confirm Disable System',
      enableSystem: 'Confirm Enable System',
      stopSystemDesc: 'Are you sure you want to stop the EMS?',
      startSystemDesc: 'Are you sure you want to start the EMS?',
      disableSystemDesc: 'Are you sure you want to disable the Service?',
      enableSystemDesc: 'Are you sure you want to enable the Service?',
      emsAlgorithms: 'EMS Algorithms',
      setPoints: 'Set Points',
      setting: 'Settings',
      updateFirmware: 'UPDATE FIRMWARE',
      updateSoftware: 'UPDATE SOFTWARE',
      setOfEmsAlgorithms: 'Set Of EMS Algorithms',
      remote: {
        
      },
      inputPassword: 'Input Password',
      attachments: 'Attachments',
      settingDesc: 'Lorem ipsum dolor sit amet consectetur.',
      select: 'Select',
      cellDriverID: 'Cell Driver ID:',
      cellDriverList: 'Cell Driver List',
      associatedAsset: 'Associated Asset',
      setSoc: 'Set Soc'
    },
    stop: 'Stop',
    start: 'Start',
    clearAllFlags: 'Clear All Flags',
    softReset: 'Soft Reset',
    setPoint: 'Set Point',
    algorithm: 'Algorithm',
    remote: 'Remote',
    settings: 'Settings',
    updateFirmware: 'Update Firmware',
    setOfCommands: 'Set of commands',
    setSoc: 'Set Soc',
    socLimitation: 'SOC Limitation',
    updateSoftware: 'Update Software',
    weather: {
      wind: 'Wind',
      humidity: 'Humidity',
      barometer: 'Barometer',
      rain: 'Rain'
    },
    header: 'Header',
    disable: 'Disable',
    enable: 'Enable'
  },
  cellDriver: {
    confirmModal: {
      stopSystemDesc: 'Are you sure you want to stop the Cell Driver?',
      startSystemDesc: 'Are you sure you want to start the Cell Driver?',
    },
    title: 'Cell Driver Overview',
    cellList: 'Cell Grid',
    cellID: 'Cell ID:'
  },
  warning: 'Warning',
  cell: {
    title: 'Cell Overview',
    requireSelectChart: 'Maximum check is 2',
  },
  action: 'Action',
  systemState: 'System State',
  collapse: 'Collapse',
  expand: 'Expand',
  services: {
    dateTimeSetting: 'Date Time Setting',
    ipSetting: 'Ip Setting',
    optionSetting: 'Option Setting',
    boolSetting: 'Bool Setting',
    numericSetting: 'Numeric Setting',
    stringSetting: 'String Setting',
    refresh: 'Refresh',
    dhcp: 'DHCP',
    ip: 'IP',
    mask: 'Mask',
    gateway: 'Gateway',
    dns1: 'DNS 1',
    dns2: 'DNS 2',
    logLevel: 'Log Level'
  }
};

export default dashboard;
