import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { DTO } from './base.dto';
import { ResponseType } from 'axios';
import { TABLE_SORT_DIRECTION } from 'src/constants';
import { IEms } from 'src/interfaces/overview';
import { Owner } from 'src/constants/user';
import { STATUS } from 'src/constants/status';
import { Role } from 'src/interfaces/user';

export interface ITenant {
  paginatedResults?: ITenantItem[];
  current?: number;
  limit?: number;
  page?: number;
  total?: number;
}

export interface ITenantItem {
  _id?: string;
  last_name: string;
  is_active: boolean;
  first_name: string;
  name?: string;
  information?: string;

  email?: string;
  plants?: IPlanItem[];
  user_manage?: [];
  asset_status?: string;
  operating_status?: string;
  createdOn?: string;
  user_manages: [];
  status?: string;
  owners: { plant: { name: string, owner: { user: Owner} } }[];
}

export interface IPlanItem {
  _id?: string;
  tenant_id?: string;
  name?: string;
  information?: string;
  path?: number;
  type?: number;
  locations?: ILocationsItem[];
}

export interface ILocationsItem {
  _id?: string;
  tenant_id?: string;
  path?: string;
  location?: ILocationItem;
  address?: string;
  type?: string;
  name?: string;
  ems?: IEms[];
}

export interface ILocationItem {
  lat?: string;
  lng?: string;
}

export interface ITenantListRequest {
  q?: string;
  status?: string;
  is_active?: boolean;
  sortOption?: {
    column?: string;
    sortDirection?: TABLE_SORT_DIRECTION;
  };
  page?: number;
  pageSize?: number;
  limit?: number;
  assigned?: boolean;
}

export interface ITenantInfo {
  _id: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  phone_code: string;
  email: string;
  temperature_unit: string;
  owners: {
    plant: {
      _id: string;
      name: string;
      status: STATUS;
      owner: { user: Owner };
      viewers: { user: Owner, role: {role: Role} }[];
    };
  }[];
}

interface IOwnerItem {
  _id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone_number: string;
  phone_code: string;
  is_active: boolean;
  is_first_login: boolean;
  createdOn: string;
  updatedOn: string;
  activation_code: string;
  avatar?: string;
  temperature_unit: string;
}

export interface IViewers {
  createdOn?: string;
  position?: string;
  target?: string;
  target_model?: string;
  type?: string;
  updatedOn?: string;
  user_id?: string;
  _id?: string;
  user: IOwnerItem;
}

export interface IPlants {
  _id: string;
  name: string;
  tenant_id: string;
  path: string;
  type: string;
  createdOn: string;
  updatedOn: boolean;
  status?: string;
  owner: IViewers;
  viewers?: IViewers[];
  is_deleted?: boolean;
  location_name?: string;
}

export class ListTenantDTO extends DTO {
  public param: object | undefined;
  public query: ITenantListRequest | undefined;
  public body: undefined;
  public url: string = ENDPOINT.GET_TENANT_LIST;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(request?: ITenantListRequest) {
    super();
    this.query = request;
  }
}

export class ListTenantNoPermissionDTO extends DTO {
  public param: object | undefined;
  public query: ITenantListRequest | undefined;
  public body: undefined;
  public url: string = ENDPOINT.GET_TEANANT_DASHBOARD;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(request?: ITenantListRequest) {
    super();
    this.query = request;
  }
}

export interface PlantsCreateItem {
  id: string;
  owners_id: string[] | undefined;
}

export interface BodyCreateTenantDTO {
  name?: string;
  information?: string;
  user_id?: string[];
  plants?: string[];
}

export interface BodyUpdateTenantDTO {
  name?: string;
  information?: string;
  user_id?: string[];
  plants?: string[];
}
export interface ResponseTenantDTO {
  responseCode: string;
}

export interface BodyAssignDTO {
  plants?: PlantsCreateItem[];
}

export class CreateTenantDTO extends DTO {
  public param: object | undefined;
  public query: object | undefined;
  public body: unknown | undefined;
  public url: string = ENDPOINT.CREATE_TENANT;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: BodyCreateTenantDTO) {
    super();
    this.body = body;
  }
}

export class UpdateTenantDTO extends DTO {
  public param: object | undefined;
  public query: object | undefined;
  public body: unknown | undefined;
  public url: string = ENDPOINT.UPDATE_TENANT;
  public method: HTTP_METHOD = HTTP_METHOD.PUT;
  public readonly responseType: ResponseType = 'json';
  constructor(body: BodyUpdateTenantDTO, param: { id: string }) {
    super();
    this.body = body;
    this.param = param;
  }
}

export class DeleteTenantDTO extends DTO {
  public param: { tenantId: string };
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.DELETE_TENANT;
  public method: HTTP_METHOD = HTTP_METHOD.DELETE;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { tenantId: string }) {
    super();
    this.param = param;
  }
}

export class GetTenantDetailDTO extends DTO {
  public param: { id: string };
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.DETAIL_TENANT;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { id: string }) {
    super();
    this.param = param;
  }
}

export class AssignPlantOwnerDTO extends DTO {
  public param: object | undefined;
  public query: object | undefined;
  public body: unknown | undefined;
  public url: string = ENDPOINT.ASSIGN_PLANT;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: BodyAssignDTO) {
    super();
    this.body = body;
  }
}

export class ValidNameTenantDTO extends DTO {
  public param: object | undefined;
  public query: object | undefined;
  public body: unknown | undefined;
  public url: string = ENDPOINT.GET_VALID_NAME_TENANT;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: { name: string }) {
    super();
    this.body = body;
  }
}

export class ListPlantTenantDTO extends DTO {
  public param: object | undefined;
  public query: { tenant_id: string } | undefined;
  public body: undefined;
  public url: string = ENDPOINT.GET_TENANT_PLANTS;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(query: { tenant_id: string }) {
    super();
    this.query = query;
  }
}

export class RemovePlantTenantDTO extends DTO {
  public param: { id: string } | undefined;
  public query: object | undefined;
  public body: { plant_id: string } | undefined;
  public url: string = ENDPOINT.REMOVE_PLANT_TENANT;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { id: string }, body: { plant_id: string }) {
    super();
    this.param = param;
    this.body = body;
  }
}
