import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { DTO } from './base.dto';
import { ResponseType } from 'axios';
import { TABLE_SORT_DIRECTION } from 'src/constants';

export interface IUserActivityLogs {
  paginatedResults?: IUserActivityLogsItem[];
  current?: number;
  limit?: number;
  page?: number;
  total?: number;
}
export interface IUserActivityLogsItem {
  _id?: string;
  createdOn?: string;
  updatedOn?: string;
  action_type?: string;
  action_by?: IActionByItem;
  target_object: ITargetObjectItem;
  related_object?: IRelatedObjectItem;
}

export interface IActionByItem {
  _id?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  avatar?: string;
  is_active?: boolean;
  role?: IRole;
}

export interface ITargetObjectItem {
  type?: string;
  info: IInfoItem;
  oldValue: IOldNewValue;
  newValue: IOldNewValue;
}

export interface IInfoItem {
  name?: string;
  email?: string;
  action?: string;
  plant_name?: string;
  tenant_name?: string;
  id?: string;
  level?: string;
  location_name?: string;
  status?: string;
  alarm_type?: string[];
  description?: any;
  system_state?: string;
}

export interface IOldNewValue {
  permissions?: IPermission[];
  first_name?: string;
  last_name?: string;
  status?: string;
  role?: string;
  name?: string;
  information?: string;
  tenant_name?: string;
  address?: string;
  phone_number?: string;
  avatar?: string;
  notes?: string;
}

export interface IPermission {
  action?: string;
  id?: string;
  name?: string;
}

export interface IRelatedObjectItem {
  type?: string;
  info: IInfoItem;
}

export interface IRole {
  _id?: string;
  name?: string;
  role?: string;
}

export interface IUserActivityLogsListRequest {
  q?: string;
  status?: string;
  level?: string;
  role?: string;
  type?: string;
  sortOption?: {
    column?: string;
    sortDirection?: TABLE_SORT_DIRECTION;
  };
  page?: number;
  pageSize?: number;
  limit?: number;
}

export interface IAccountParam {
  accountId?: string;
}

export interface ResponseAlarmDTO {
  responseCode: string;
}

export interface BodyUpdateAlarmDTO {
  status?: string;
  notes?: string;
}

export class ListUserActivityLogsDTO extends DTO {
  public param: object | undefined;
  public query: IUserActivityLogsListRequest | undefined;
  public body: undefined;
  public url: string = ENDPOINT.GET_USER_ACTIVITY_LOGS_LIST;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(request?: IUserActivityLogsListRequest) {
    super();
    this.query = request;
  }
}
