import { getSeverity, getStatus, getStatusAlarm } from 'src/constants/utils';

export const userActivityLogs = {
  label: {
    activityType: 'Activity Type',
    activityDescription: 'Activity Description'
  },

  placeholder: {
    search: 'Search by Email'
  },

  activity: {
    loggedIn: 'Logged In',
    loggedInDESC: 'Logged in to the EMS platform.',
    loggedOut: 'Logged Out',
    loggedOutDESC: 'Logged out fromt the EMS platform.',
    activatedAccount: 'Activated Account',
    activatedAccountDESC: 'Activated their account.',
    resentActivationLink: 'Resent Activation Link',
    resentActivationLinkDESC: (email?: string) =>
      `Resent activation link to user <b>${email}</b> whose previous activation link has expired.`,
    requestedPasswordReset: 'Requested Password Reset',
    createdAccount: 'Created Account',
    createdAccountDESC: (email?: string) =>
      `Created a new account with the email address <b>${email}</b>.`,
    updatedAccount: 'Updated Account',
    updatedAccountDESC1: (
      email?: string,
      newStatus?: string,
      oldStatus?: string
    ) =>
      `Updated the following information for the email <b>${email}</b>:<br>● Updated status from <b>${getStatus(
        oldStatus
      )}</b> to <b>${getStatus(newStatus)}</b>`,
    updatedAccountDESC2: (email?: string, newRole?: string, oldRole?: string) =>
      `Updated the following information for the email ${email}:<br>● Updated role from  <b>${oldRole}</b> to <b>${newRole}</b>`,
    updatedAccountDESC3: (
      email?: string,
      newName?: string,
      newPhone?: string
    ) =>
      `Updated the following information for the email ${email}:
      ${newName ? '<br>● Updated name' : ''} 
      ${newPhone ? '<br>● Updated phone number' : ''}`,
    updatedAccountDESC4:
      'Updated their account information. Changes include:<br>● Updated password',
    updatedAccountDESC5: (
      newName?: string,
      newPhone?: string,
      newAvatar?: string
    ) =>
      `Updated their account information. Changes include:
      ${newName ? '<br>● Updated name' : ''}
      ${newPhone ? '<br>● Updated phone number' : ''}
      ${newAvatar ? '<br>● Updated avatar' : ''}`,
    createdTenant: 'Created Tenant',
    createdTenantDESC1: (tenantName?: string) =>
      `Created a new Tenant with the name <b>${tenantName}</b>.`,
    createdTenantDESC2: (plantName?: string, tenantName?: string) =>
      `Allocated Plant <b>${plantName}</b> to Tenant <b>${tenantName}</b>.`,
    createdTenantDESC3: (email?: string, tenantName?: string) =>
      `<b>${email}</b> has been assigned as the owner of Tenant <b>${tenantName}</b>.`,
    createdTenantDESC4: (email?: string, plantName?: string) =>
      `<b>${email}</b> has been assigned as the owner of Plant <b>${plantName}</b>.`,
    updatedTenant: 'Updated Tenant',
    updatedTenantDESC1: (
      tenantName?: string,
      oldTenantName?: string,
      newTenantName?: string,
      newTenantInfomation?: string
    ) =>
      `Updated the following information for the Tenant <b>${tenantName}</b>:
      ${
        newTenantName
          ? `<br>● Updated name from <b>${oldTenantName}</b> to <b>${newTenantName}</b>`
          : ''
      }
     ${newTenantInfomation ? '<br>● Updated information' : ''}`,
    updatedTenantDESC2: (tenantName?: string) =>
      `Updated the following information for the Tenant <b>${tenantName}</b>:<br>● Updated information`,
    updatedTenantDESC3: (tenantName?: string, plantName?: string) =>
      `Updated the following information for the Tenant <b>${tenantName}</b>:<br>● Removed Plant <b>${plantName}</b>`,
    updatedTenantDESC4: (tenantName?: string, plantName?: string) =>
      `Updated the following information for the Tenant <b>${tenantName}</b>:<br>● Added Plant <b>${plantName}</b>`,
    updatedTenantDESC5: (email?: string, tenantName?: string) =>
      `<b>${email}</b> has been assigned as the owner of Tenant <b>${tenantName}</b>.`,
    updatedTenantDESC6: (email?: string, plantName?: string) =>
      `<b>${email}</b> has been assigned as the owner of Plant <b>${plantName}</b>.`,
    updatedTenantDESC7: (email?: string, tenantName?: string) =>
      `Removed <b>${email}</b> as the owner of Tenant <b>${tenantName}</b>.`,
    updatedTenantDESC8: (email?: string, plantName?: string) =>
      `Removed <b>${email}</b> as the owner of Plant <b>${plantName}</b>.`,
    autoUpdatedOfTenantStatus: 'Auto-updated of Tenant Status',
    autoUpdatedOfTenantStatusDESC: (
      tenantName?: string,
      oldStatus?: string,
      newStatus?: string
    ) =>
      `The status of Tenant <b>${tenantName}</b> has been updated from <b>${getStatus(
        oldStatus
      )}</b> to <b>${getStatus(newStatus)}</b>.`,
    deleteTenant: 'Delete Tenant',
    deleteTenantDESC: (tenantName?: string) =>
      `Deleted the Tenant <b>${tenantName}</b>.`,
    createPlant: 'Create Plant',
    createPlantDESC1: (plantName?: string) =>
      `Created a new Plant with the name <b>${plantName}</b>.`,
    createPlantDESC2: (plantName?: string, tenantName?: string) =>
      `Allocated Plant <b>${plantName}</b> to Tenant <b>${tenantName}</b>.`,
    createPlantDESC3: (email?: string, plantName?: string) =>
      `<b>${email}</b> has been assigned as the owner of Plant <b>${plantName}</b>.`,
    createPlantDESC4: (email?: string, locationName?: string) =>
      `<b>${email}</b> has been assigned as the owner of Location <b>${locationName}</b>.`,
    updatedPlant: 'Updated Plant',
    updatedPlantDESC1: (
      plantName?: string,
      oldPlantName?: string,
      newPlantName?: string
    ) =>
      `Updated the following information for the Plant <b>${plantName}</b>:<br>● Updated Plant name from <b>${oldPlantName}</b> to <b>${newPlantName}</b>`,
    updatedPlantDESC2: (
      plantName?: string,
      newTenantName?: string
    ) =>
      `Updated the following information for the Plant <b>${plantName}</b>:<br>● Reallocated to Tenant <b>${newTenantName}`,
    updatedPlantDESC3: (
      plantName?: string,
      oldLocationName?: string,
      newLocationName?: string
    ) =>
      `Updated the following information for the Plant <b>${plantName}</b>:<br>● Updated Location Name from <b>${oldLocationName}</b> to <b>${newLocationName}</b>`,
    updatedPlantDESC4: (
      plantName?: string,
      locationName?: string,
      oldLocationAddress?: string,
      newLocationAddress?: string
    ) =>
      `Updated the following information for the Plant <b>${plantName}</b>:<br>● Updated Location Address of Location <b>${locationName}</b> from <b>${oldLocationAddress}</b> to <b>${newLocationAddress}</b>`,
    updatedPlantDESC5: (plantName?: string, locationName?: string) =>
      `Updated the following information for the Plant <b>${plantName}</b>:<br>● Added Location <b>${locationName}</b>`,
    updatedPlantDESC6: (plantName?: string, locationName?: string) =>
      `Updated the following information for the Plant <b>${plantName}</b>:<br>● Deleted Location <b>${locationName}</b>`,
    updatedPlantDESC7: (
      plantName?: string,
      emsName?: string,
      locationName?: string
    ) =>
      `Updated the following information for the Plant <b>${plantName}</b>:<br>● Removed EMS <b>${emsName}</b> from Location <b>${locationName}</b>`,
    updatedPlantDESC8: (
      plantName?: string,
      emsName?: string,
      locationName?: string
    ) =>
      `Updated the following information for the Plant <b>${plantName}</b>:<br>● Allocated EMS <b>${emsName}</b> to Location <b>${locationName}</b>`,
    updatedPlantDESC9: (email?: string, plantName?: string) =>
      `<b>${email}</b> has been assigned as the owner of Plant <b>${plantName}</b>.`,
    updatedPlantDESC10: (email?: string, locationName?: string) =>
      `<b>${email}</b> has been assigned as the owner of Location <b>${locationName}</b>.`,
    updatedPlantDESC11: (email?: string, plantName?: string) =>
      `Removed <b>${email}</b> as the owner of Plant <b>${plantName}</b>.`,
    updatedPlantDESC12: (email?: string, locationName?: string) =>
      `Removed <b>${email}</b> as the owner of Location <b>${locationName}</b>.`,
    autoUpdatedOfPlantStatus: 'Auto-updated of Plant Status',
    autoUpdatedOfPlantStatusDESC: (
      tenantName?: string,
      oldStatus?: string,
      newStatus?: string
    ) =>
      `The status of Plant <b>${tenantName}</b> has been updated from <b>${getStatus(
        oldStatus
      )}</b> to <b>${getStatus(newStatus)}</b>.`,
    deletedPlant: 'Deleted Plant',
    deletedPlantDESC: (plantName?: string) =>
      `Deleted the Plant <b>${plantName}</b>.`, //
    createdEMS: 'Created EMS',
    createdEMSDESC1: (emsName?: string) =>
      `Created a new EMS with the name <b>${emsName}</b>.`,
    createdEMSDESC2: (
      emsName?: string,
      tenantName?: string,
      plantName?: string,
      locationName?: string
    ) =>
      `Allocated EMS <b>${emsName}</b> to Tenant/Plant/Location: <b>${tenantName}</b>/${plantName}/<b>${locationName}</b>.`,
    updatedEMS: 'Updated EMS',
    updatedEMSDESC1: (
      emsName?: string,
      oldEMSName?: string,
      newEmsName?: string
    ) =>
      `Updated the following information for the EMS <b>${emsName}</b>:<br>● Updated EMS name from <b>${oldEMSName}</b> to <b>${newEmsName}</b>`,
    updatedEMSDESC2: (emsName?: string) =>
      `Updated the following information for the EMS <b>${emsName}</b>:<br>● Associated Assets`,
    updatedEMSDESC3: (emsName?: string) =>
      `Updated the following information for the EMS <b>${emsName}</b>:<br>● Downloaded new certificates and keys`,
    autoUpdatedOfEMSStatus: 'Auto-updated of EMS Status',
    autoUpdatedOfEMSStatusDESC1: (
      emsName?: string,
      newValue?: string,
      oldValue?: string
    ) =>
      `Detected that new data has been pushed from EMS <b>${emsName}</b> and automatically updated the EMS status from <b>${getStatus(
        oldValue
      )}</b> to <b>${getStatus(newValue)}</b>.`,
    autoUpdatedOfEMSStatusDESC2: (emsName?: string) =>
      `Manually stopped the EMS <b>${emsName}</b> by clicking the 'STOP' button. The EMS status was automatically updated from <b>Active</b> to <b>Inactive</b>.`,
    autoUpdatedOfEMSStatusDESC3: (emsName?: string) =>
      `Manually started the EMS <b>${emsName}</b> by clicking the 'START' button. The EMS status was automatically updated from <b>Inactive</b> to <b>Active</b>.`,
    deletedEMS: 'Deleted EMS',
    deletedEMSDESC: (emsName?: string) => `Deleted the EMS <b>${emsName}</b>.`, //
    updatedAlarm: 'Updated Alarm',
    updatedAlarmDESC: (
      alarmId?: string,
      oldStatus?: string,
      newStatus?: string,
      newNotes?: string
    ) =>
      `Updated the following information for the Alarm ID <b>${alarmId}</b>:
      ${
        newStatus
          ? `<br>● Updated status from <b>${getStatusAlarm(
              oldStatus
            )}</b> to <b>${getStatusAlarm(newStatus)}</b>`
          : ''
      }
      ${newNotes ? '<br>● Notes' : ''}`,
    receivedAlarm: 'Received Alarm',
    receivedAlarmDESC: (
      alarmId?: string,
      systemState?: string,
      alarmDESC?: any
    ) =>
      `An alarm with ID <b>${alarmId}</b> has been received.<br>System State: ${systemState}<br>Alarm Type/Description:${alarmDESC
        .map((item: any) => {
          return `<br>● ${getSeverity(item.alarm_type)}: ${item.message.join(
            ', '
          )}`;
        })
        .join('')}`,
    commandExecutionResult: 'Command Execution Result',
    commandExecutionResultDESC: (
      commandName?: string,
      triggeredLevel?: string,
      tenantName?: string,
      plantName?: string,
      locationName?: string,
      status?: string
    ) =>
      `Received result after triggering the <b>${commandName?.replace(/-/g,' ')}</b> command on <b>${triggeredLevel}</b> level of <b>${plantName}</b>. The status of the command execution is <b>${status}</b>.`,
    updatedRolePermission: 'Updated Role/Permission',
    updatedRolePermissionDESC: (
      role?: string,
      removedPermissionName?: string[],
      addedPermissionName?: string[]
    ) =>
      `The following updates were made to the <b>${role}</b> role:${
        removedPermissionName
          ? `<br>● Removed permission(s): <b>${removedPermissionName}</b>`
          : ''
      } ${
        addedPermissionName
          ? `<br>● Added permission(s): <b>${addedPermissionName}</b>`
          : ''
      }`
  }
};

export default userActivityLogs;
