const tenantEntity = {
  title: {
    createTenant: 'Create Tenant',
    updateTenant: 'Update Tenant',
    titleModal: 'Association Relationship',
    addPlant: 'Add Plant'
  },

  label: {
    plants: 'Plant(s)',
    confirmTenantInfo: 'Confirm Tenant Info',
    tenantInformation: 'Tenant Information',
    selectPlants: 'Select Plant(s)',
    selectPlant: 'Select Plant',
    listPlant: 'Plant List',
    assignmentOwner: 'Assignment Owner',
    associatedPlants: 'Associated Plants'
  },

  drawer: {
    addUser: 'Add User',
    selected: 'Selected',
    list: 'List'
  },

  placeholder: {
    search: 'Search by Tenant Name, Email, Plant, Plant Owner'
  },

  button: {
    createTenant: 'Create Tenant',
    updateTenant: 'Update Tenant',
    deleteTenant: 'Delete Tenant',
    addPlant: 'Add Plant',
    addOwner: 'Add Owner',
  },
  tab: {
    tenantInfo: 'Tenant Info',
    plants: 'Plants'
  },
  
  detail: {
    plantOwner: 'Plant Owner',
    title: 'Tenant Details',
    adminViewerAssignment: 'Admin/Viewer Assignment'
  }
};

export default tenantEntity;
