import { getSeverity } from 'src/constants/utils';

/*eslint-disable*/
const notifications = {
  title: 'Notifications',
  button: {
    markAllAsRead: 'Mark all as read',
    markAsRead: 'Mark as read',
    seeAll: 'See all'
  },
  label: {
    system: 'System'
  },
  notificationAppFunction: {
    automatedProcess: (
      alarmId?: string,
      systemState?: string,
      alarmDESC?: any
    ) =>
      `An alarm with ID <b>${alarmId}</b> has been received.<br>System State: ${systemState}<br>Alarm Type/Description:${alarmDESC
        .map((item: any) => {
          return `<br>● ${getSeverity(item.alarm_type)}: ${item.message.join(
            ', '
          )}`;
        })
        .join('')}`
  },
  notificationApp : {
    adminUpdateAccountInformation: `Updated the following information for the email <b>{{insertEmail}}</b>:
      <br/>● updated role to <b>{{insertNewRole}}</b>`,
    createTenant: `Created a new Tenant with the name <b>{{insertTenantName}}</b>`,
    createTenantAllocatedPlant: `Allocated Plant <b>{{insertPlantName}}</b> to Tenant <b>{{insertTenantName}}</b>`,
    createTenantOwnerOfTenant: `<b>{{insertEmail}}</b> has been assigned as the owner of Tenant <b>{{insertTenantName}}</b>`,
    createTenantOwnerOfPlant: `<b>{{insertEmail}}</b> has been assigned as the owner of Plant <b>{{insertPlantName}}</b>`,
    updateTenant: `Updated the following information for the Tenant <b>{{insertNewTenantName}}</b>:
      <br/>● updated name from <b>{{insertOldTenantName}}</b> to <b>{{insertNewTenantName}}</b>`,
    updateTenantUpdatedInformation: `Updated the following information for the Tenant <b>{{insertTenantName}}</b>:
      <br/>● updated information`,
    updateTenantRemovedPlant: `Updated the following information for the Tenant <b>{{insertTenantName}}</b>:
      <br/>● removed Plant <b>{{insertPlantName}}</b>`,
    updateTenantAddedPlant: `Updated the following information for the Tenant<b>{{insertTenantName}}</b>:
      <br/>● added Plant <b>{{insertPlantName}}</b>`,
    updateTenantOwnerOfTenant: `<b>{{insertEmail}}</b> has been assigned as the owner of Tenant <b>{{insertTenantName}}</b>`,
    updateTenantOwnerOfPlant: `<b>{{insertEmail}}</b> has been assigned as the owner of Plant </b><b>{{insertPlantName}}</b>`,
    updateTenantRemoveOwnerOfTenant: `Removed <b>{{insertEmail}}</b> as the owner of Tenant <b>{{insertTenantName}}</b>`,
    updateTenantRemoveOwnerOfPlant: `Removed <b>{{insertEmail}}</b> as the owner of Plant <b>{{insertPlantName}}</b>`,
    autoUpdatedOfTenantStatus: `The status of Tenant <b>{{insertTenantName}}</b> has been updated from '<b>{{insertOldStatus}}</b>' to '<b>{{insertNewStatus}}</b>'.`,
    deleteTenant: `Deleted the Tenant <b>{{insertTenantName}}</b>`,
    createPlant: `Created a new Plant with the name <b>{{insertPlantName}}</b>`,
    createPlantAllocatedPlantToTenant: `Allocated Plant <b>{{insertPlantName}}</b> to Tenant <b>{{insertTenantName}}</b>`,
    createPlantAssignedAsTheOwnerOfPlant: `<b>{{insertEmail}}</b> has been assigned as the owner of Plant <b>{{insertPlantName}}</b>`,
    createPlantAssignedAsTheViewOfPlant: `<b>{{insertEmail}}</b> has been assigned as the Admin/ View Assignment of Plant <b>{{insertPlantName}}</b>`,
    createPlantOwnerOfLocation: `<b>{{insertEmail}}</b> has been assigned as the owner of Location <b>{{insertLocationName}}</b>`,
    updatePlant: `Updated the following information for the Plant <b>{{insertNewPlantName}}</b>:
    <br/>● updated Plant name from <b>{{insertOldPlantName}}</b> to <b>{{insertNewPlantName}}</b>`,
    updatePlantReallocatedFromTenant: `Updated the following information for the Plant <b>{{insertPlantName}}</b>:
    <br/>● reallocated from Tenant <b>{{insertOldTenantName}}</b> to Tenant <b>{{insertNewTenantName}}</b>`,
    updatePlantUpdatedLocationName: `Updated the following information for the Plant <b>{{insertPlantName}}</b>:
    <br/>● updated Location Name from <b>{{insertOldLocationName}}</b> to <b>{{insertNewLocationName}}</b>`,
    updatePlantUpdatedLocationAddressOfLocation: `Updated the following information for the Plant <b>{{insertPlantName}}</b>:
    <br/>● updated Location Address of Location <b>{{insertLocationName}}</b> from <b>{{insertOldLocationAddress}}</b> to <b>{{insertNewLocationAddress}}</b>`,
    updatePlantAddedLocation: `Updated the following information for the Plant <b>{{insertPlantName}}</b>:
    <br/>● added Location <b>{{insertLocationName}}</b>`,
    updatePlantDeletedLocation: `Updated the following information for the Plant <b>{{insertPlantName}}</b>:
    <br/>● deleted Location <b>{{insertLocationName}}</b>`,
    updatePlantRemovedEMSfromLocation: `Updated the following information for the Plant <b>{{insertPlantName}}</b>:
    <br/>● removed EMS <b>{{insertEMSName}}</b> from Location <b>{{insertLocationName}}</b>`,
    updatePlantAllocatedEMStoLocation: `Updated the following information for the Plant <b>{{insertPlantName}}</b>:
    <br/>● allocated EMS <b>{{insertEMSName}}</b> to Location <b>{{insertLocationName}}</b>`,
    updatePlantAssignedAsTheOwnerOfPlant: `<b>{{insertEmail}}</b> has been assigned as the owner of Plant <b>{{insertPlantName}}</b>`,
    updatePlantAssignedAsTheViewOfPlant: `<b>{{insertEmail}}</b> has been assigned as the Admin/ View Assignment of Plant <b>{{insertPlantName}}</b>`,
    updatePlantAssignedOwnerLocation: `<b>{{insertEmail}}</b> has been assigned as the owner of Location <b>{{insertLocationName}}</b>`,
    updatePlantRemovedOwnerOfPlant: `Removed <b>{{insertEmail}}</b> as the owner of Plant <b>{{insertPlantName}}</b>`,
    updatePlantRemovedViewOfPlant: ` Removed <b>{{insertEmail}}</b> as the Admin/ View Assignment of Plant <b>{{insertPlantName}}</b>`,
    updatePlantRemovedOwnerOfLocation: `Removed <b>{{insertEmail}}</b> as the owner of Location <b>{{insertLocationName}}</b>`,
    autoUpdatedOfPlantStatus: `The status of Plant <b>{{insertPlantName}}</b> has been updated from <b>{{insertOldStatus}}</b> to <b>{{insertNewStatus}}</b>.`,
    deletePlant: `Deleted the Plant <b>{{insertPlantName}}</b>`,
    createEMS: `Created a new EMS with the name <b>{{insertEMSName}}</b>`,
    createEMSAllocatedEMS: `Allocated EMS <b>{{insertEMSName}}</b> to Tenant/Plant/Location: <b>{{insertTenantName}}</b>/<b>{{insertPlantName}}</b>/<b>{{insertLocationName}}</b>`,
    updateEMS: `Updated the following information for the EMS <b>{{insertNewEMSName}}</b>:
      <br/>● updated EMS name from <b>{{insertOldEMSName}}</b> to <b>{{insertNewEMSName}}</b>`,
    updatePlantEMS: `Updated the following information for the EMS <b>{{insertEMSName}}</b>:
    <br/>● Associated Assets`,
    updatePlantEMS2: `Updated the following information for the EMS <b>{{insertEMSName}}</b>:
    <br/>● Downloaded new certificates and keys`,
    autoUpdatedOfStatusEMS: `Detected that new data has been pushed from EMS <b>{{insertEMSName}}</b> and automatically updated the EMS status from "Pending Setup" to "Active".`,
    autoUpdatedOfStatusEMS2: `Manually stopped the EMS <b>{{insertEMSName}}</b> by clicking the 'STOP' button. The EMS status was automatically updated from 'Active' to 'Inactive'.`,
    autoUpdatedOfStatusEMS3: `Manually started the EMS <b>{{insertEMSName}}</b> by clicking the 'START' button. The EMS status was automatically updated from 'Inactive' to 'Active'.`,
    deleteEMS: `Deleted the EMS <b>{{insertEMSName}}</b>`,
    // automatedProcess: `An alarm with ID <b>{{insertAlarmID}}</b>, severity <b>{{insertSeverity}}</b>, and level <b>{{insertLevel}}</b> has been received. The description for the alarm is <b>{{insertDescription}}</b>.`,
    automatedProcess: `An alarm with ID <b>{{insertAlarmID}}</b> has been received.
    System State:  <b>{{insertSeverity}}</b>
    Alarm Type/Description: 
    <b>{{insertDescription}}</b>`,
    triggerCommand: `Received result after triggering the <b>{{insertCommandName}}</b> command on <b>{{insertLevelThatReceivedCommand}}</b> level of <b>{{insertTenantPlantLocationEntity}}</b>. The status of the command execution is <b>{{insertStatus}}</b>.`,
  }
};

export default notifications;
