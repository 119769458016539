import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { PAGINATION_CONFIGURATION, TABLE_SORT_DIRECTION } from 'src/constants';
import { HTTP_STATUS_RESPONSE_KEY } from 'src/constants/api';
import { PaginationResponseDTO, ResponseDTO } from 'src/dto/base.dto';
import {
  AddEmsDTO,
  CreateEmsDTO,
  DeleteEmsDTO,
  DownloadEmsDTO,
  GetEmsAddLocationDTO,
  GetEmsDetailDTO,
  GetEmsDeviceDTO,
  IBodyAddEms,
  IEms,
  IEmsItem,
  IEmsListRequest,
  ListEmsDTO,
  RemoveEmsDTO,
  UpdateEmsDTO
} from 'src/dto/ems.dto';
import { IDownloadEms, IEmsForm } from 'src/interfaces/form/ems';
import { IObSelect } from 'src/pages/ems/ems-action/ems-action';
import { IHttpService } from 'src/services/http.service';

const DEFAULT_SORT_COLUMN = 'updateTime';

export interface IGetEmsDetail {
  _id: string;
  name?: string;
  tenant_id?: string;
  tenant: {
    name: string;
    owners: Array<IObSelect & {id: string}>;
  };
  location: {
    name: string;
    owners: Array<IObSelect & {id: string}>;
    address: string;
    parent?: {
      name: string;
      owners: Array<IObSelect & {id: string}>;
    }[]
  };
  location_id?: string;
  associated_assets?: {
    name?: string;
    information?: string;
    device_type_id?: string;
  }[];
  status?: string; 
}

export interface IGetEmsFile {
  file: {
    type: string;
    data: ArrayBufferLike;
  };
  name: string;
}

export interface IGetEmsFiles {
  files:  IGetEmsFile[];
  id?: string;
}


export interface IEmsListStore {
  listEms: IEmsItem[];
  totalPages: number;
  totalRecords: number;
  pageSize: number;
  pageNumber: number;
  sortBy: string;
  sortDirection: TABLE_SORT_DIRECTION;
  getEmsForm?: IEmsForm;
  fetchList(request?: IEmsListRequest): Promise<void>;
  addEms(bodyAddEms: IBodyAddEms): Promise<ResponseDTO<any>>;
  getListEms(query: {
    page?: string;
    limit?: number;
  }): Promise<ResponseDTO<any>>;
  getListDevice(query: {
    page?: string;
    limit?: number;
  }): Promise<ResponseDTO<any>>;
  createEms(body: IEmsForm): Promise<ResponseDTO<IGetEmsFiles>>;
  updateEms(
    body: IEmsForm,
    param: { id: string }
  ): Promise<ResponseDTO<string>>;
  removeEms(param: { id: string }): Promise<boolean>;
  deleteEms(param: { id: string }): Promise<boolean>;
  getEmsDetail(param: {id: string}): Promise<ResponseDTO<IGetEmsDetail>>
  getDetailEms(param: { id: string }): Promise<ResponseDTO<IGetEmsDetail>>;
  getListEmsAddLocation(query: {
    page?: string;
    limit?: number;
  }): Promise<ResponseDTO<IEms>>;
  downloadEms(body: IDownloadEms): Promise<ResponseDTO<IGetEmsFile[]>>;
}
export class EmsListStore implements IEmsListStore {
  listEms: IEmsItem[] = [];
  totalPages = 0;
  totalRecords = 0;
  pageSize = PAGINATION_CONFIGURATION.DEFAULT_PAGE_SIZE;
  pageNumber = PAGINATION_CONFIGURATION.DEFAULT_PAGE;
  sortBy = DEFAULT_SORT_COLUMN;
  sortDirection = TABLE_SORT_DIRECTION.DESC;
  getEmsForm?: IEmsForm;

  constructor(private readonly http: IHttpService) {
    makeAutoObservable(this, {
      listEms: observable.ref,
      getEmsForm: observable.ref,
      configSortOption: action.bound
    });
  }

  public configSortOption(sortBy: string, sortDirection: TABLE_SORT_DIRECTION) {
    this.sortBy = sortBy;
    this.sortDirection = sortDirection;
  }

  public async fetchList(request: IEmsListRequest): Promise<void> {
    const requestDTO = new ListEmsDTO(request);

    const listEmsApplication: PaginationResponseDTO<IEms> =
      await this.http.request<ListEmsDTO, IEms>(requestDTO);

    if (listEmsApplication.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      runInAction(() => {
        this.listEms =
          listEmsApplication.data &&
          (listEmsApplication.data.paginatedResults as any);
        this.totalPages =
          (listEmsApplication.data && listEmsApplication.data.total) || 0;
        this.totalRecords = listEmsApplication.totalRecords || 0;
        this.pageSize =
          listEmsApplication.pageSize ||
          PAGINATION_CONFIGURATION.DEFAULT_PAGE_SIZE;
        this.pageNumber =
          listEmsApplication.pageNumber ||
          PAGINATION_CONFIGURATION.DEFAULT_PAGE;
      });
    }
  }
  public async addEms(bodyAddEms: IBodyAddEms): Promise<ResponseDTO<any>> {
    const addEmsDto = new AddEmsDTO(bodyAddEms);
    return await this.http.request(addEmsDto);
  }

  public async getListEms(query: {
    page?: string;
    limit?: number;
  }): Promise<ResponseDTO<any>> {
    const requestDTO = new GetEmsAddLocationDTO(query);
    return await this.http.request(requestDTO);
  }

  public async getListEmsAddLocation(query: {
    page?: string;
    limit?: number;
  }): Promise<ResponseDTO<IEms>> {
    const requestDTO = new GetEmsAddLocationDTO(query);
    return await this.http.request(requestDTO);
  }
  public async removeEms(param: { id: string }): Promise<boolean> {
    const removeEmsDto = new RemoveEmsDTO(param);
    const res = await this.http.request(removeEmsDto);
    if (res.responseCode === HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      return true;
    }
    return false;
  }
  public async deleteEms(param: { id: string }): Promise<boolean> {
    const deleteEmsDto = new DeleteEmsDTO(param);
    const res = await this.http.request(deleteEmsDto);
    if (res.responseCode === HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      return true;
    }
    return false;
  }

  public async getListDevice(query: {
    page?: string;
    limit?: number;
  }): Promise<ResponseDTO<any>> {
    const requestDTO = new GetEmsDeviceDTO(query);
    return await this.http.request(requestDTO);
  }

  public async createEms(body: IEmsForm) {
    const createEmsDto = new CreateEmsDTO(body);
    const res: ResponseDTO<IGetEmsFiles> = await this.http.request(
      createEmsDto
    );
    return res;
  }

  public async getEmsDetail(param: {
    id: string;
  }): Promise<ResponseDTO<IGetEmsDetail>> {
    const getEmsDetailDto = new GetEmsDetailDTO(param);
    const res: ResponseDTO<IGetEmsDetail> = await this.http.request(
      getEmsDetailDto
    );
    return res;
  }

  public async getDetailEms(query: { id: string }) {
    const getEmsDetailDTO = new GetEmsDetailDTO(query);
    const res: ResponseDTO<IGetEmsDetail> = await this.http.request(getEmsDetailDTO);
    return res;
  }
  public async updateEms(body: IEmsForm, param: { id: string }) {
    const updateEmsDto = new UpdateEmsDTO(body, param);
    const res: ResponseDTO<string> = await this.http.request(updateEmsDto);
    return res;
  }

  public async downloadEms(body: IDownloadEms ): Promise<ResponseDTO<IGetEmsFile[]>> {
    const downloadEmsDto = new DownloadEmsDTO(body);
    const res: ResponseDTO<IGetEmsFile[]> = await this.http.request(
      downloadEmsDto
    );
    return res;
  }
}
