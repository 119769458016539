import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { DTO } from './base.dto';
import { ResponseType } from 'axios';
import { IDictionary } from 'src/interfaces/dictionary';

export interface IServiceItem {
  _id?: string;
  name?: string;
  version?: string;
  enable?: string;
  device_id?: string;
  dictionary?: IDictionary[];
}
export interface IService {
  paginatedResults?: IServiceItem[];
  current?: number;
  limit?: number;
  page?: number;
  total?: number;
}

export class OverviewServiceDTO extends DTO {
  public param: { id: string } | undefined;
  public query: object | undefined;
  public body: undefined;
  public url: string = ENDPOINT.GET_OVERVIEW_SERVICE;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { id: string }) {
    super();
    this.param = param;
  }
}


export interface IOverviewDictionaryDTO {
  ems_id?: string;
  tenant_id?: string;
  service_id?: string;
}
export class OverviewDictionaryDTO extends DTO {
  public param: object | undefined;
  public query: IOverviewDictionaryDTO | undefined;
  public body: undefined;
  public url: string = ENDPOINT.GET_OVERVIEW_DICTIONARY;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(query: IOverviewDictionaryDTO) {
    super();
    this.query = query;
  }
}

export interface ISettingDictionary {
  target: string;
  service_name: string;
  dictionary: IDictionary[];
}

export class SettingDictionaryDTO extends DTO {
  public param: object | undefined;
  public query: object | undefined;
  public body: ISettingDictionary;
  public url: string = ENDPOINT.SETTING_DICTIONARY;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: ISettingDictionary) {
    super();
    this.body = body;
  }
}
