import { ResponseType } from 'axios';
import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { DTO } from './base.dto';


export interface ITimeseriesStatistic {
  '3PhaseSOC'?: number;
  '3PhaseSOH'?: number;
  '3PhasekWhAvailable'?: number;
  'localLoadskW'?: number
  'total3PhaseACkW'?: number;
  'totalkWhAbsorbed'?: number;
  'totalkWhInjected'?: number;
}
export interface ITenantOverview {
  tenant_overview: ITenantOverviewItem;
  ems_statistics?: IEmsStatistics;
  tenantEnergyStatisticsInfo?: ITenantEnergyStatisticsInfo;
  timeseries_statistic?: ITimeseriesStatistic;
  alarms?: IAlarmItem[];
  lastUpdatedOn?: string;
}
export interface ITenantOverviewItem {
  _id?: string;
  createdOn?: string;
  updatedOn?: string;
  name?: string;
  information?: string;
  plants: IPlantItem[];
}

export interface IEmsStatistics {
  total?: number;
  numOfActive?: number;
  numOfInactive?: number;
  numOfPendingSetup: number;
  numOfIdle?: number;
  lastUpdatedOn: string;
  data?: any;
}
export interface IEmsItem {
  _id: string;
  createdOn?: string;
  updatedOn?: string;
  location_id: string;
  name: string;
  tenant_id: string;
  type: string;
  status: string;
}

export interface I3PhaseStatistics {
  '3PhasekWhAvailable'?: number;
  'total3PhaseACkW'?: number;
}

export interface IPlantItem {
  _id: string;
  createdOn: string;
  updatedOn: string;
  tenant_id: string;
  latitude: number;
  longitude: number;
  name: string;
  information: string;
  path: string;
  type: string;
  status?: string;
  location: ILocationsItem;
  alarm?: IAlarnInfoItem[];
  address?: string;
  statisticAlarm?: IAlarnInfoItem;
  ems_statistics?: IEmsStatistics;
  '3PhaseStatistics': I3PhaseStatistics;
  location_name?: string;
}

export interface ITenantEnergyStatisticsInfo {
  timeseriesUntilNowData?: ITimeseriesUntilNowDataItem;
  timeseriesTodayEachPlant?: ITimeseriesToday[];
}

export interface ITimeseriesUntilNowDataItem {
  totalkWhAbsorbed?: number;
  total3PhaseACkW?: number;
  ['3PhasekWhAvailable']?: number;
  ['totalkWhInjected']?: number;
}

export interface ITimeseriesToday {
  createdOn: string;
  is_deleted: boolean;
  name: string;
  path: string;
  status: string;
  tenant_id: string;
  type: string;
  updatedOn: string;
  _id: string;
  consumption: IConsumption;
}

export interface IConsumption {
  total3PhaseACkW?: string;
  ['3PhasekWhAvailable']?: number;
}
export interface IAlarmItem {
  _id: string;
  device_id: string;
  tenant_id: string;
  location_id: string;
  alarm_code: string;
  level: string;
  event_type: string;
  description: IDescriptionItem[];
  alarm_type: string[];
  notes: string;
  status: string;
  timestamp: string;
  timeseries_id: string;
  timeseries: ITimeseriesItem;
  location: ILocationsItem;
  plant: IPlantItem;
}

export interface ITimeseriesItem {
  _id: string;
  timestamp: string;
  metadata?: any;
  phaseVac: string;
  systemState: string;
  systemAlarms: string;
  batteryAlarms: string;
  faultType: string;
  phaseFrequency: string;
  phase: string;
  ['3PhaseSOC']: string;
}

export interface ILocationsItem {
  _id?: string;
  createdOn: string;
  updatedOn: string;
  tenant_id?: string;
  path?: string;
  location?: ILocationItem;
  address?: string;
  type?: string;
  ems?: IEmsItem;
  name?: string;
  status?: string;
}

export interface ILocationItem {
  type?: string;
  latitude: number;
  longitude: number;
}

export interface IDescriptionItem {
  alarm_type?: string;
  message: string[];
}
export interface IAlarnInfoItem {
  batteryAlarms?: number;
  faultType?: number;
  systemAlarms?: number;
}

export class TenantOverviewDTO extends DTO {
  public param: { id: string };
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.GET_TENANT_OVERVIEW;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { id: string }) {
    super();
    this.param = param;
  }
}
