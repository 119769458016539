import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { PAGINATION_CONFIGURATION, TABLE_SORT_DIRECTION } from 'src/constants';
import { HTTP_STATUS_RESPONSE_KEY } from 'src/constants/api';
import {  ResponseDTO } from 'src/dto/base.dto';
import {
  BodyCreatePlantDTO,
  BodyUpdatePlantDTO,
  CheckExistingPlantNameDTO,
  CreatePlantDTO,
  DeletePlantDTO,
  DownloadCertificateDTO,
  GetPlantDetailDTO,
  GetUserAssignOwnerByEmailDTO,
  GetUserAssignViewerByEmailDTO,
  IPlantListRequest,
  IUserOwnerResponseGetByEmail,
  ListPlantAddTenantDTO,
  ListPlantDTO,
  ResponsePlantDTO,
  UpdatePlanttDTO,
} from 'src/dto/plant.dto';
import { ILocations, IPlantForm } from 'src/interfaces/form/plants';
import { IHttpService } from 'src/services/http.service';
import { IGetEmsFile } from '../ems/ems.store';
import { IPlantDetail, IPlantItem, IPlantListCenter } from 'src/constants/plant';
import { IBodyGetUserAssignByEmail } from 'src/dto/account-management-list.dto';

const DEFAULT_SORT_COLUMN = 'updateTime';

export interface IPlantListStore {
  listPlant: IPlantItem[];
  totalPages: number;
  totalRecords: number;
  pageSize: number;
  pageNumber: number;
  sortBy: string;
  sortDirection: TABLE_SORT_DIRECTION;
  fetchList(request?: IPlantListRequest): Promise<void>;
  fetchListPlantAddTenant(): Promise<ResponseDTO<IPlantItem[]>>;
  getDetailPlant(param: { id: string }): Promise<ResponseDTO<IPlantDetail>>;
  configSortOption(sortBy: string, sortDirection: TABLE_SORT_DIRECTION): void;
  updatePlant(
    body: BodyUpdatePlantDTO,
    param: { id: string }
  ): Promise<ResponseDTO<ResponsePlantDTO>>;
  deletePlant(param: {
    plantId: string;
  }): Promise<ResponseDTO<ResponsePlantDTO>>;
  createPlant(body: BodyCreatePlantDTO): Promise<ResponseDTO<{files: IGetEmsFile[],id: string}>>;
  checkExistingName(body: {name: string}): Promise<ResponseDTO<undefined>>;
  downloadCertificate(param: {id: string} ): Promise<ResponseDTO<{files: IGetEmsFile[]}>>;
  getUserAssignViewerByEmail(body: IBodyGetUserAssignByEmail): Promise<ResponseDTO<IUserOwnerResponseGetByEmail>>;
  getUserAssignOwnerByEmail(body: IBodyGetUserAssignByEmail): Promise<ResponseDTO<IUserOwnerResponseGetByEmail>>;
}
export class PlantListStore implements IPlantListStore {
  listPlant: IPlantItem[] = [];
  totalPages = 0;
  totalRecords = 0;
  pageSize = PAGINATION_CONFIGURATION.DEFAULT_PAGE_SIZE;
  pageNumber = PAGINATION_CONFIGURATION.DEFAULT_PAGE;
  sortBy = DEFAULT_SORT_COLUMN;
  sortDirection = TABLE_SORT_DIRECTION.DESC;

  constructor(private readonly http: IHttpService) {
    makeAutoObservable(this, {
      listPlant: observable.ref,
      configSortOption: action.bound
    });
  }

  public configSortOption(sortBy: string, sortDirection: TABLE_SORT_DIRECTION) {
    this.sortBy = sortBy;
    this.sortDirection = sortDirection;
  }

  public async fetchList(request: IPlantListRequest): Promise<void> {
    const requestDTO = new ListPlantDTO(request);
    const res: ResponseDTO<IPlantListCenter>=
      await this.http.request<ListPlantDTO, IPlantListCenter>(requestDTO);

    

    if (res.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      const listPlantApplication = res.data
      runInAction(() => {

        this.listPlant =  listPlantApplication?.paginatedResults || []
        this.totalPages =
          listPlantApplication?.total || 0;

        this.totalRecords = listPlantApplication?.total || 0;
        
        this.pageSize =
          listPlantApplication?.limit ||
          PAGINATION_CONFIGURATION.DEFAULT_PAGE_SIZE;
        this.pageNumber =
          listPlantApplication?.page ||
          PAGINATION_CONFIGURATION.DEFAULT_PAGE;
      });
    }
  }
  public async fetchListPlantAddTenant(): Promise<ResponseDTO<IPlantItem[]>> {
    const requestDTO = new ListPlantAddTenantDTO();
     return  await this.http.request<ListPlantDTO, IPlantItem[]>(requestDTO);
    
  }

  public async deletePlant(param: { plantId: string }) {
    const deletePlantDTO = new DeletePlantDTO(param);
    const res: ResponseDTO<ResponsePlantDTO> = await this.http.request(
      deletePlantDTO
    );
    return res;
  }

  public async getDetailPlant(query: { id: string }) {
    const getPlantDetailDTO = new GetPlantDetailDTO(query);
    const res: ResponseDTO<IPlantDetail> = await this.http.request(
      getPlantDetailDTO
    );
    return res;
  }

  public async updatePlant(body: BodyUpdatePlantDTO, param: { id: string }) {
    const updatePlantDTO = new UpdatePlanttDTO(body, param);
    const res: ResponseDTO<ResponsePlantDTO> = await this.http.request(
      updatePlantDTO
    );
    return res;
  }

  public async createPlant(body: BodyCreatePlantDTO) {
    const createPlantDto = new CreatePlantDTO(body);
    const res: ResponseDTO<{files: IGetEmsFile[],id: string}> = await this.http.request(
      createPlantDto
    );
    return res;
  }


  public async checkExistingName(body: {name: string}) { 
    const checkExistingNameDto = new CheckExistingPlantNameDTO(body);
    const res: ResponseDTO<undefined> = await this.http.request(
      checkExistingNameDto
    );
    return res;
  }
   
  public async downloadCertificate(param: {id: string} ): Promise<ResponseDTO<{files: IGetEmsFile[]}>> {
    const downloadCertificateDto = new DownloadCertificateDTO (param);
    const res: ResponseDTO<{files: IGetEmsFile[]}> = await this.http.request(
      downloadCertificateDto
    );
    return res;
  }
  public async getUserAssignOwnerByEmail(body: IBodyGetUserAssignByEmail): Promise<ResponseDTO<IUserOwnerResponseGetByEmail>> {
    const getUserAssignOwnerByEmailDTO  = new GetUserAssignOwnerByEmailDTO(body)
    return await this.http.request<GetUserAssignOwnerByEmailDTO,IUserOwnerResponseGetByEmail>(getUserAssignOwnerByEmailDTO)
  }
  public async getUserAssignViewerByEmail(body: IBodyGetUserAssignByEmail): Promise<ResponseDTO<IUserOwnerResponseGetByEmail>> {
    const getUserAssignViewerByEmailDTO  = new GetUserAssignViewerByEmailDTO(body)
    return await this.http.request<GetUserAssignViewerByEmailDTO,IUserOwnerResponseGetByEmail>(getUserAssignViewerByEmailDTO)
  }
  
}

export interface IPlantStore {
  plantDetail?: any;
  plantLocations?: any;
  setPlantLocation: (value: ILocations, idx: number) => void;
  setReset: () => void;
}

export class PlantStore implements IPlantStore {
  plantDetail?: any;
  plantLocations?: IPlantForm;
  constructor(private readonly http: IHttpService) {
    makeAutoObservable(this, {
      plantDetail: observable.ref,
      plantLocations: observable.ref,
      setPlantLocation: action.bound
    });
  }
  public setPlantLocation(values: any, idx: number): void {
    if (!values) {
      this.plantLocations = {
        locations: values
      };
      this.plantDetail = values;
      return;
    }
    this.plantLocations = {
      locations: {
        location: values.location,
        address: values.address,
        idx: idx
      }
    };
    this.plantDetail = {
      values: values,
      key: idx
    };
  }

  public setReset(): void {
    this.plantLocations = {
      locations: []
    };
  }
}
