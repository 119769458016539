import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { PAGINATION_CONFIGURATION, TABLE_SORT_DIRECTION } from 'src/constants';
import { HTTP_STATUS_RESPONSE_KEY } from 'src/constants/api';
import { PaginationResponseDTO } from 'src/dto/base.dto';
import {
  IUserActivityLogs,
  IUserActivityLogsItem,
  IUserActivityLogsListRequest,
  ListUserActivityLogsDTO
} from 'src/dto/user-activity-logs.dto';
import { IHttpService } from 'src/services/http.service';

const DEFAULT_SORT_COLUMN = 'updateTime';

export interface IUserActivityLogsListStore {
  listUser: IUserActivityLogsItem[];
  totalPages: number;
  totalRecords: number;
  pageSize: number;
  pageNumber: number;
  sortBy: string;
  sortDirection: TABLE_SORT_DIRECTION;
  fetchList(request?: IUserActivityLogsListRequest): Promise<void>;
  configSortOption(sortBy: string, sortDirection: TABLE_SORT_DIRECTION): void;
}
export class UserActivityLogsListStore implements IUserActivityLogsListStore {
  listUser: IUserActivityLogsItem[] = [];
  totalPages = 0;
  totalRecords = 0;
  pageSize = PAGINATION_CONFIGURATION.DEFAULT_PAGE_SIZE;
  pageNumber = PAGINATION_CONFIGURATION.DEFAULT_PAGE;
  sortBy = DEFAULT_SORT_COLUMN;
  sortDirection = TABLE_SORT_DIRECTION.DESC;

  constructor(private readonly http: IHttpService) {
    makeAutoObservable(this, {
      listUser: observable.ref,
      configSortOption: action.bound
    });
  }

  public configSortOption(sortBy: string, sortDirection: TABLE_SORT_DIRECTION) {
    this.sortBy = sortBy;
    this.sortDirection = sortDirection;
  }

  public async fetchList(request: IUserActivityLogsListRequest): Promise<void> {
    const requestDTO = new ListUserActivityLogsDTO(request);

    const listUserApplication: PaginationResponseDTO<IUserActivityLogs> =
      await this.http.request<ListUserActivityLogsDTO, IUserActivityLogs>(
        requestDTO
      );

    if (listUserApplication.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      runInAction(() => {
        this.listUser =
          listUserApplication.data &&
          (listUserApplication.data.paginatedResults as any);
        this.totalPages =
          (listUserApplication.data && listUserApplication.data.total) || 0;
        this.totalRecords = listUserApplication.totalRecords || 0;
        this.pageSize =
          listUserApplication.pageSize ||
          PAGINATION_CONFIGURATION.DEFAULT_PAGE_SIZE;
        this.pageNumber =
          listUserApplication.pageNumber ||
          PAGINATION_CONFIGURATION.DEFAULT_PAGE;
      });
    }
  }
}
