import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { DTO } from './base.dto';
import { ResponseType } from 'axios';
import { TABLE_SORT_DIRECTION } from 'src/constants';
import { IDownloadEms, IEmsForm } from 'src/interfaces/form/ems';
import { IPlanItem } from './tenant-list.dto';

export interface IEms {
  paginatedResults?: IEmsItem[];
  current?: number;
  limit?: number;
  page?: number;
  total?: number;
}
export interface IEmsItem {
  _id: string;
  location_id?: string;
  tenant_id?: string;
  name?: string;
  type?: string;
  is_delete?: boolean;
  status?: string;
  createdOn?: string;
  updatedOn?: string;
  owners?: IOwnerItem[];
  tenant?: ITenant;
  location?: ILocations;
  plant?: IPlanItem;
}

export interface IOwnerItem {
  _id?: string;
  user_id?: string;
  type?: string;
  target?: string;
  target_model?: string;
  createdOn?: string;
  updatedOn?: string;
  user?: IUserItem[];
}
export interface IUserItem {
  _id: string;
  email: string;
  avatar: string;
}

export interface ILocations {
  _id?: string;
  tenant_id?: string;
  name?: string;
  location?: ILocationItem;
  address?: string;
  path?: string;
  createdOn?: string;
  updatedOn?: string;
  type?: string;
  status?: string;
  is_delete?: boolean;
}

export interface ILocationItem {
  latitude?: number;
  longitute?: number;
  type?: string;
  weather_key?: string;
}

export interface IEmsListRequest {
  q?: string;
  status?: string;
  sortOption?: {
    column?: string;
    sortDirection?: TABLE_SORT_DIRECTION;
  };
  page?: number;
  pageSize?: number;
  limit?: number;
}

interface ITenant {
  _id: string;
  name: string;
  information: string;
  updatedOn: string;
  createdOn: string;
  is_deleted: boolean;
  status: string;
}

export class ListEmsDTO extends DTO {
  public param: object | undefined;
  public query: IEmsListRequest | undefined;
  public body: undefined;
  public url: string = ENDPOINT.GET_EMS_LIST;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(request?: IEmsListRequest) {
    super();
    this.query = request;
  }
}
export interface IBodyAddEms {
  tenant_id: string;
  locations: [
    {
      location_id: string;
      device_id: string;
    }
  ];
}
export class AddEmsDTO extends DTO {
  public param: undefined;
  public query: undefined;
  public body: IBodyAddEms;
  public url: string = ENDPOINT.ADD_EMS;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: IBodyAddEms) {
    super();
    this.body = body;
  }
}
export class RemoveEmsDTO extends DTO {
  public param: { id: string };
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.REMOVE_EMS;
  public method: HTTP_METHOD = HTTP_METHOD.DELETE;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { id: string }) {
    super();
    this.param = param;
  }
}
export class GetEmsAddLocationDTO extends DTO {
  public param: undefined;
  public query: { page?: string; limit?: number };
  public body: undefined;
  public url: string = ENDPOINT.GET_EMS_ADD_LOCATION;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(query: { page?: string; limit?: number }) {
    super();
    this.query = query;
  }
}

export class DeleteEmsDTO extends DTO {
  public param: { id: string };
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.DELETE_EMS;
  public method: HTTP_METHOD = HTTP_METHOD.DELETE;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { id: string }) {
    super();
    this.param = param;
  }
}
export class GetEmsDetailDTO extends DTO {
  public param: { id: string };
  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.GET_EMS_DETAIL;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(param: { id: string }) {
    super();
    this.param = param;
  }
}

export class CreateEmsDTO extends DTO {
  public param: object | undefined;
  public query: object | undefined;
  public body: IEmsForm;
  public url: string = ENDPOINT.CREATE_EMS;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType?: ResponseType = 'json';
  constructor(body: IEmsForm) {
    super();
    this.body = body;
  }
}
export class GetEmsDeviceDTO extends DTO {
  public param: undefined;
  public query: { page?: string; limit?: number };
  public body: undefined;
  public url: string = ENDPOINT.GET_DEVICE_TYPE;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType?: ResponseType = 'json';
  constructor(query: { page?: string; limit?: number }) {
    super();
    this.query = query;
  }
}

export class UpdateEmsDTO extends DTO {
  public param: object | undefined;
  public query: object | undefined;
  public body: IEmsForm;
  public url: string = ENDPOINT.UPDATE_EMS;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType?: ResponseType = 'json';
  constructor(body: IEmsForm, param: {id: string}) {
    super();
    this.body = body;
    this.param = param;
  }
}

export class DownloadEmsDTO extends DTO {
  public param: object | undefined;
  public query: object | undefined;
  public body: IDownloadEms;
  public url: string = ENDPOINT.DOWNLOAD_EMS;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType?: ResponseType = 'json';
  constructor(body: IDownloadEms) {
    super();
    this.body = body;
  }
}
