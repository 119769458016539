import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { DTO } from './base.dto';
import { ResponseType } from 'axios';

export interface IOverviewFetch {
  from?: string;
  to?: string;
  type?: string;
  ems_id?: string;
  device_id?: string;
  plant_id?: string;
  timezone?: string;
}

export class OverviewEmsDTO extends DTO {
  public param: object | undefined;
  public query: IOverviewFetch | undefined;
  public body: undefined;
  public url: string = ENDPOINT.GET_OVERVIEW_EMS;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(request?: IOverviewFetch) {
    super();
    this.query = request;
  }
}
export class OverviewCellDriverDTO extends DTO {
  public param: object | undefined;
  public query: IOverviewFetch | undefined;
  public body: undefined;
  public url: string = ENDPOINT.GET_OVERVIEW_CELL_DRIVER;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(request?: IOverviewFetch) {
    super();
    this.query = request;
  }
}

export class OverviewCellDTO extends DTO {
  public param: object | undefined;
  public query: IOverviewFetch | undefined;
  public body: undefined;
  public url: string = ENDPOINT.GET_OVERVIEW_CELL;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(request?: IOverviewFetch) {
    super();
    this.query = request;
  }
}

export class OverviewPlantDTO extends DTO {
  public param: object | undefined;
  public query: IOverviewFetch | undefined;
  public body: undefined;
  public url: string = ENDPOINT.GET_OVERVIEW_PLANT;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(request?: IOverviewFetch) {
    super();
    this.query = request;
  }
}
