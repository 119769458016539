import { ResponseType } from 'axios';
import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { DTO } from './base.dto';
import { AssetStatus, OperatingStatus, TenantResponse } from 'src/constants/tenant';
import { TABLE_SORT_DIRECTION } from 'src/constants';
import { ILocation } from 'src/constants/plant';




export interface IQueryListTenant{
    page: number;
    limit: number;
    sort_by: string;
    sort_order: TABLE_SORT_DIRECTION;
    q: string;
    operating_status: OperatingStatus;
    asset_status: AssetStatus;
}

export interface GetListTenantResponse{
    current: number,
    limit: number,
    page: number
    paginatedResults: Array<TenantResponse>
}
export class GetListTenantDTO extends DTO {
    public param:  undefined;
    public query: Partial<IQueryListTenant>;
    public body: undefined;
    public url: string = ENDPOINT.GET_LIST_TENANT;
    public method: HTTP_METHOD = HTTP_METHOD.GET;
    public readonly responseType: ResponseType = 'json';
    constructor(query: Partial<IQueryListTenant>) {
      super();
      this.query = query;
    }

}
    
export interface IGetListTenantTreeResponse {
    _id: string;
    name: string;
    locations: ILocation[]
}
export class GetListTenantTreeDTO extends DTO {
    public param:  undefined;
    public query: undefined;
    public body: undefined;
    public url: string = ENDPOINT.GET_LIST_TENANT_TREE;
    public method: HTTP_METHOD = HTTP_METHOD.GET;
    public readonly responseType: ResponseType = 'json';
    constructor() {
      super();
    }
}