import { makeAutoObservable, observable } from 'mobx';
import { HTTP_STATUS_RESPONSE_KEY } from 'src/constants/api';
import { PaginationResponseDTO, ResponseDTO } from 'src/dto/base.dto';
import {
  IOverviewDictionaryDTO,
  IService,
  IServiceItem,
  ISettingDictionary,
  OverviewDictionaryDTO,
  OverviewServiceDTO,
  SettingDictionaryDTO
} from 'src/dto/service.dto';
import { IDictionary } from 'src/interfaces/dictionary';

import { IHttpService } from 'src/services/http.service';

export interface IServiceStore {
  fetchService(param: { id: string }): Promise<void>;
  fetchDictionary(query: IOverviewDictionaryDTO): Promise<void>
  listService: IServiceItem[];
  dictionary: IDictionary[];
  settingDictionary(body: ISettingDictionary): Promise<ResponseDTO<string>>;
  dispose(): void;
}

export class ServiceStore implements IServiceStore {
  listService: IServiceItem[] = [];
  dictionary: IDictionary[] = [];
  constructor(private readonly http: IHttpService) {
    makeAutoObservable(this, {
      listService: observable.ref,
      dictionary: observable.ref
    });
  }

  public async fetchService(param: { id: string }): Promise<void> {
    const overviewServicedTO = new OverviewServiceDTO(param);
    const res: PaginationResponseDTO<IService> = await this.http.request<
      OverviewServiceDTO,
      IService
    >(overviewServicedTO);
    if (res.responseCode === HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      this.listService = res.data?.paginatedResults || [];
    }
  }

  public async fetchDictionary(query: IOverviewDictionaryDTO): Promise<void> {
    const overviewDictionaryDto = new OverviewDictionaryDTO(query);
    const res: ResponseDTO<IDictionary[]> = await this.http.request<
      OverviewDictionaryDTO,
      IDictionary[]
    >(overviewDictionaryDto);
    if (res.responseCode === HTTP_STATUS_RESPONSE_KEY.SUCCESS && res.data) {
      this.dictionary = res.data;
    }
  }

  public async settingDictionary(body: ISettingDictionary) {
    const settingDictionaryDto = new SettingDictionaryDTO(body);
    const res: ResponseDTO<string> = await this.http.request(settingDictionaryDto);
    return res;
  }

  public dispose(): void {
    this.dictionary = []
  }
}
