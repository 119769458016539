import { action, makeAutoObservable, observable, runInAction } from 'mobx';
import { PAGINATION_CONFIGURATION, TABLE_SORT_DIRECTION } from 'src/constants';
import { HTTP_STATUS_RESPONSE_KEY } from 'src/constants/api';
import {
  BodyUpdateAlarmDTO,
  GetAlarmDetailDTO,
  IAlarm,
  IAlarmInfo,
  IAlarmItem,
  IAlarmListRequest,
  ListAlarmDTO,
  ResponseAlarmDTO,
  UpdateAlarmDTO
} from 'src/dto/alarm.dto';
import { PaginationResponseDTO, ResponseDTO } from 'src/dto/base.dto';
import { IHttpService } from 'src/services/http.service';

const DEFAULT_SORT_COLUMN = 'updateTime';

export interface IAlarmListStore {
  listAlarm: IAlarmItem[];
  totalPages: number;
  totalRecords: number;
  pageSize: number;
  pageNumber: number;
  sortBy: string;
  sortDirection: TABLE_SORT_DIRECTION;
  fetchList(request?: IAlarmListRequest): Promise<void>;
  configSortOption(sortBy: string, sortDirection: TABLE_SORT_DIRECTION): void;
  getDetailAlarm(param: { id: string }): Promise<ResponseDTO<IAlarmInfo>>;
  updateAlarm(
    body: BodyUpdateAlarmDTO,
    param: { id: string }
  ): Promise<ResponseDTO<ResponseAlarmDTO>>;
}
export class AlarmListStore implements IAlarmListStore {
  listAlarm: IAlarmItem[] = [];
  totalPages = 0;
  totalRecords = 0;
  pageSize = PAGINATION_CONFIGURATION.DEFAULT_PAGE_SIZE;
  pageNumber = PAGINATION_CONFIGURATION.DEFAULT_PAGE;
  sortBy = DEFAULT_SORT_COLUMN;
  sortDirection = TABLE_SORT_DIRECTION.DESC;

  constructor(private readonly http: IHttpService) {
    makeAutoObservable(this, {
      listAlarm: observable.ref,
      configSortOption: action.bound
    });
  }

  public configSortOption(sortBy: string, sortDirection: TABLE_SORT_DIRECTION) {
    this.sortBy = sortBy;
    this.sortDirection = sortDirection;
  }

  public async fetchList(request: IAlarmListRequest): Promise<void> {
    const requestDTO = new ListAlarmDTO(request);

    const listAlarmApplication: PaginationResponseDTO<IAlarm> =
      await this.http.request<ListAlarmDTO, IAlarm>(requestDTO);

    if (listAlarmApplication.responseCode == HTTP_STATUS_RESPONSE_KEY.SUCCESS) {
      runInAction(() => {
        this.listAlarm =
          listAlarmApplication.data &&
          (listAlarmApplication.data.paginatedResults as any);
        this.totalPages =
          (listAlarmApplication.data && listAlarmApplication.data.total) || 0;
        this.totalRecords = listAlarmApplication.totalRecords || 0;
        this.pageSize =
          listAlarmApplication.pageSize ||
          PAGINATION_CONFIGURATION.DEFAULT_PAGE_SIZE;
        this.pageNumber =
          listAlarmApplication.pageNumber ||
          PAGINATION_CONFIGURATION.DEFAULT_PAGE;
      });
    }
  }

  public async getDetailAlarm(query: { id: string }) {
    const getAlarmDetailDTO = new GetAlarmDetailDTO(query);
    const res: ResponseDTO<IAlarmInfo> = await this.http.request(
      getAlarmDetailDTO
    );
    return res;
  }

  public async updateAlarm(body: BodyUpdateAlarmDTO, param: { id: string }) {
    const updateAlarmDTO = new UpdateAlarmDTO(body, param);
    const res: ResponseDTO<ResponseAlarmDTO> = await this.http.request(
      updateAlarmDTO
    );
    return res;
  }
}
