import { TABLE_SORT_DIRECTION } from 'src/constants';
import { DTO } from './base.dto';
import { ResponseType } from 'axios';
import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';

export interface ICommandLogsManagement {
  paginatedResults?: ICommandLogsManagementItem[];
  current?: number;
  limit?: number;
  page?: number;
  total?: number;
}

export interface ICommandLogsListRequest {
  q?: string;
  status?: string;
  level?: string;
  command?: string;
  sortOption?: {
    column?: string;
    sortDirection?: TABLE_SORT_DIRECTION;
  };
  page?: number;
  pageSize?: number;
  limit?: number;
}

export class CommandLogsManagementDTO extends DTO {
  public param: object | undefined;
  public query: ICommandLogsListRequest | undefined;
  public body: undefined;
  public url: string = ENDPOINT.GET_COMMAND_LOGS;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(request?: ICommandLogsListRequest) {
    super();
    this.query = request;
  }
}

export interface ICommandLogsManagementItem {
  _id?: string;
  updatedOn?: string;
  createdOn?: string;
  user_id?: string;
  device_id?: string;
  command?: string;
  status?: string;
  level?: string;
  user?: {
    _id: string;
    email: string;
  };
  device?: {
    _id: string;
    name: string;
  };
  ems?: {
    _id: string;
    name: string;
    type: string;
    status: string;
  };
  cell_driver?: {
    _id: string;
    name: string;
    id_map: string;
    type: string;
    status: string;
  };
  tenant?: {
    _id: string;
    name: string;
  };
  location?: {
    _id: string;
    name: string;
  };
  plant?: {
    _id: string;
    name: string;
    address: string;
    
  };
}

export class ListCommandLogsDTO extends DTO {
  public param: object | undefined;
  public query: ICommandLogsListRequest | undefined;
  public body: undefined;
  public url: string = ENDPOINT.GET_COMMAND_LOGS;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';
  constructor(request?: ICommandLogsListRequest) {
    super();
    this.query = request;
  }
}
