import { ENDPOINT, HTTP_METHOD } from 'src/constants/api';
import { DTO } from './base.dto';
import { ResponseType } from 'axios';
import { ALGORITHM_ID_KEY } from 'src/pages/dashboard/modal-confirm/modal-confirm';
import { IDisable } from 'src/interfaces/command';

export class StopEmsDTO extends DTO {
  public param: undefined;
  public query: undefined;
  public body: { target: string, password: string };
  public url: string = ENDPOINT.COMMAND_EMS_STOP;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: { target: string, password: string }) {
    super();
    this.body = body;
  }
}

export class StopHubDTO extends DTO {
  public param: undefined;
  public query: undefined;
  public body: { target: string, password: string };
  public url: string = ENDPOINT.COMMAND_HUB_STOP;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: { target: string, password: string }) {
    super();
    this.body = body;
  }
}

export class StartEmsDTO extends DTO {
  public param: undefined;
  public query: undefined;
  public body: { target: string, password: string };
  public url: string = ENDPOINT.COMMAND_EMS_START;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: { target: string, password: string }) {
    super();
    this.body = body;
  }
}

export class StartHubDTO extends DTO {
  public param: undefined;
  public query: undefined;
  public body: { target: string, password: string };
  public url: string = ENDPOINT.COMMAND_HUB_START;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: { target: string, password: string }) {
    super();
    this.body = body;
  }
}

export class ClearAllFlagsDTO extends DTO {
  public param: undefined;
  public query: undefined;
  public body: { target: string, password: string };
  public url: string = ENDPOINT.COMMAND_EMS_CLEAR_ALL_FLAGS;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: { target: string, password: string }) {
    super();
    this.body = body;
  }
}

export class SoftResetDTO extends DTO {
  public param: undefined;
  public query: undefined;
  public body: { target: string, password: string };
  public url: string = ENDPOINT.COMMAND_EMS_SOFT_RESET;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: { target: string, password: string }) {
    super();
    this.body = body;
  }
}

export class GetURLDTO extends DTO {
  public param: { fileName: string }; // Adjust the type to match the base class

  public query: undefined;
  public body: undefined;
  public url: string = ENDPOINT.COMMAND_EMS_GET_URL;
  public method: HTTP_METHOD = HTTP_METHOD.GET;
  public readonly responseType: ResponseType = 'json';

  constructor(fileName: string) {
    super();
    this.param = { fileName }; // Assign as an object with a fileName property
  }
}


export class CustomIPCDTO extends DTO {
  public param: undefined;
  public query: undefined;
  public body: { target: string, requests: any, tag: any, topic: any, level: any };
  public url: string = ENDPOINT.COMMAND_EMS_CUSTOM_IPC;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: { target: string, requests: any, tag: any, topic: any, level: any }) {
    super();
    this.body = body;
  }
}


export class CustomIPCResponseDTO extends DTO {
  public param: undefined;
  public query: undefined;
  public body: { target: string, requests: any, tag: any, topic: any, level: any };
  public url: string = ENDPOINT.COMMAND_EMS_CUSTOM_IPC_RESPONSE;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: { target: string, requests: any, tag: any, topic: any, level: any }) {
    super();
    this.body = body;
  }
}


export interface IBodySetPoint {
  target?: string;
  value?: number;
  password?: string;
}

export class SetHubPointDTO extends DTO {
  public param: undefined;
  public query: undefined;
  public body: IBodySetPoint;
  public url: string = ENDPOINT.SET_HUB_POINT;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: IBodySetPoint) {
    super();
    this.body = body;
  }
}

export class SetEmsPointDTO extends DTO {
  public param: undefined;
  public query: undefined;
  public body: IBodySetPoint;
  public url: string = ENDPOINT.SET_EMS_POINT;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: IBodySetPoint) {
    super();
    this.body = body;
  }
}

export class AlgorithmDTO extends DTO {
  public param: undefined;
  public query: undefined;
  public body: FormData;
  public url: string = ENDPOINT.COMMAND_ALGORITHM;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: FormData) {
    super();
    this.body = body;
  }
}

export class SettingsDTO extends DTO {
  public param: undefined;
  public query: undefined;
  public body: FormData;
  public url: string = ENDPOINT.COMMAND_SETTINGS;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: FormData) {
    super();
    this.body = body;
  }
}

export class UpdateFirmwareDTO extends DTO {
  public param: undefined;
  public query: undefined;
  public body: unknown | undefined;
  public url: string = ENDPOINT.UPDATE_FIRMWARE;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: SoftwareDTO) {
    super();
    this.body = body;
  }
}

export interface SoftwareDTO {
  target?: string;
  password?: string;
  version?: string;
  service_name?: string;
  tag?: string;
  fileName?: string;
  type?: string;
}

export class UpdateSoftwareDTO extends DTO {
  public param: undefined;
  public query: undefined;
  public body: unknown | undefined;
  public url: string = ENDPOINT.UPDATE_SOFTWARE;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: SoftwareDTO) {
    super();
    this.body = body;
  }
}

export interface RemoteResponse {
  msg: string;
  region: string;
  token: string;
}

export class RemoteDTO extends DTO {
  public param: undefined;
  public query: undefined;
  public body: { target: string };
  public url: string = ENDPOINT.REMOTE;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: { target: string }) {
    super();
    this.body = body;
  }
}

export interface IBodySetCommands {
  target: string;
  input: {
    method: 'ENABLE' | 'DISABLE';
    algorithmId: ALGORITHM_ID_KEY;
  }[];
}

export class SetCommandsDTO extends DTO {
  public param: undefined;
  public query: undefined;
  public body: IBodySetCommands;
  public url: string = ENDPOINT.SET_COMMAND;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: IBodySetCommands) {
    super();
    this.body = body;
  }
}

export interface IBodySetSOC {
  target: string;
  min_soc: number;
  max_soc: number;
}

export class SetEmsSocDTO extends DTO {
  public param: undefined;
  public query: undefined;
  public body: IBodySetSOC;
  public url: string = ENDPOINT.SET_EMS_SOC;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: IBodySetSOC) {
    super();
    this.body = body;
  }
}

export class SetHubSocDTO extends DTO {
  public param: undefined;
  public query: undefined;
  public body: IBodySetSOC;
  public url: string = ENDPOINT.SET_HUB_SOC;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: IBodySetSOC) {
    super();
    this.body = body;
  }
}

export class DisableDTO extends DTO {
  public param: undefined;
  public query: undefined;
  public body: IDisable;
  public url: string = ENDPOINT.DISABLE;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: IDisable) {
    super();
    this.body = body;
  }
}

export class EnableDTO extends DTO {
  public param: undefined;
  public query: undefined;
  public body: IDisable;
  public url: string = ENDPOINT.ENABLE;
  public method: HTTP_METHOD = HTTP_METHOD.POST;
  public readonly responseType: ResponseType = 'json';
  constructor(body: IDisable) {
    super();
    this.body = body;
  }
}

export class RefreshCommandDTO extends DTO {
  public param: {id: string} | undefined;
  public query: undefined;
  public body: undefined ;
  public url: string = ENDPOINT.REFRESH_COMMAND;
  public method: HTTP_METHOD = HTTP_METHOD.PUT;
  public readonly responseType: ResponseType = 'json';
  constructor(param: {id: string}) {
    super();
    this.param = param;
  }
}

export class RefreshServiceDTO extends DTO {
  public param: {id: string} | undefined;
  public query: undefined;
  public body: undefined ;
  public url: string = ENDPOINT.REFRESH_SERVICE;
  public method: HTTP_METHOD = HTTP_METHOD.PUT;
  public readonly responseType: ResponseType = 'json';
  constructor(param: {id: string}) {
    super();
    this.param = param;
  }
}


export class RefreshServiceSettingDTO extends DTO {
  public param: {id: string} | undefined;
  public query: undefined;
  public body: {serviceName: string};
  public url: string = ENDPOINT.REFRESH_SERVICE_SETTING;
  public method: HTTP_METHOD = HTTP_METHOD.PUT;
  public readonly responseType: ResponseType = 'json';
  constructor(param: {id: string}, body: {serviceName: string}) {
    super();
    this.param = param;
    this.body = body;
  }
}

