const alarmCenter = {
  label: {
    alarmId: 'Alarm ID',
    alarmType: 'Alarm Type',
    systemState: 'System State',
    severityAndDescription: 'Alarm Type/Description',
    notes: 'Notes',
    new: 'New',
    resolved: 'Resolved',
    acknowledged: 'Acknowledged',
    alarmStatus: 'Alarm Status',
    timeSpan: 'TimeSpan',
    phase: 'Phase',
    phaseVAC: 'Phase VAC',
    phaseSOC: '3-Phase SOC',
    batteryAlarm: 'Battery Alarm',
    systemAlarm: 'System Alarm',
    faultType: 'Fault Type',
    phaseFrequency: 'Phase Frequency',
    timesTamp: 'Timestamp',
    alarmDescription: 'Alarm Type/Description:'
  },
  alarmTypeKeyToLabel: {
    'system-alarm': 'System Alarm',
    'battery-alarm': 'Battery Alarm',
    fault: 'Fault'
  },
  systemState: {
    systemInit: 'System Initializing',
    standby: 'Standby',
    idle: 'Grid Connected - Idle',
    discharging: 'Grid Connected - Discharging',
    charging: 'Grid Connected - Charging',
    offGrid: 'Off-Grid',
    fault: 'Fault'
  },

  messageAlarm: {
    'No fault': 'No fault',
    'Grid Over-Voltage': 'Grid Over-Voltage',
    'Grid Under-Voltage': 'Grid Under-Voltage',
    'Grid Over-Frequency': 'Grid Over-Frequency',
    'Grid Under Frequency': 'Grid Under Frequency',
    'Over Current': 'Over Current',
    'Battery Error': 'Battery Error',
    'Internal Communication Error': 'Internal Communication Error',
    'ATS Error': 'ATS Error',
    'Local Device Error': 'Local Device Error',
    'Local Network Error': 'Local Network Error',
    'Local RS-485 Error': 'Local RS-485 Error',
    'External Network Error': 'External Network Error',
    'System Start': 'System Start',
    'Charge Power Limiting': 'Charge Power Limiting',
    'Discharge Power Limiting': 'Discharge Power Limiting',
    'Internal System Error': 'Internal System Error',
    'Grid Voltage Error': 'Grid Voltage Error',
    'Grid Frequency Error': 'Grid Frequency Error',
    'Over Current Error': 'Over Current Error',
    'System Over Temperature': 'System Over Temperature',
    'System Under Temperature': 'System Under Temperature',
    'Low SOC': 'Low SOC',
    'High SOC': 'High SOC',
    'System Communication Error': 'System Communication Error',
    'Reserved For Future': 'Reserved For Future',
    'Phase A - Internal Error': 'Phase A - Internal Error',
    'Phase B - Internal Error': 'Phase B - Internal Error',
    'Phase C - Internal Error': 'Phase C - Internal Error',
    'Phase A - Over Current Error': 'Phase A - Over Current Error',
    'Phase B - Over Current Error': 'Phase B - Over Current Error',
    'Phase C - Over Current Error': 'Phase C - Over Current Error',
    'Phase A - Over Temperature': 'Phase A - Over Temperature',
    'Phase B - Over Temperature': 'Phase B - Over Temperature',
    'Phase C - Over Temperature': 'Phase C - Over Temperature',
    'Phase A - Under Temperature': 'Phase A - Under Temperature',
    'Phase B - Under Temperature': 'Phase B - Under Temperature',
    'Phase C - Under Temperature': 'Phase C - Under Temperature',
    'Phase A - Low SOC': 'Phase A - Low SOC',
    'Phase B - Low SOC': 'Phase B - Low SOC',
    'Phase C - Low SOC': 'Phase C - Low SOC',
    'Phase A - High SOC': 'Phase A - High SOC',
    'Phase B - High SOC': 'Phase B - High SOC',
    'Phase C - High SOC': 'Phase C - High SOC',
    'Over Voltage': 'Over Voltage',
    'Under Voltage': 'Under Voltage',
    'Over Temperature': 'Over Temperature',
    'Under Temperature': 'Under Temperature',
    'Internal Error': 'Internal Error',
    'SCI Error': 'SCI Error',
    'CAN Error': 'CAN Error'
  },
  placeholder: {
    search: 'Search by Alarm ID,Plant , Location'
  },

  button: {
    updateStatus: 'Update Status'
  }
};

export default alarmCenter;
